import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TeamIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9826 6.6654C20.6153 5.95788 21 5.02386 21 4C21 1.79086 19.2091 0 17 0C14.7909 0 13 1.79086 13 4C13 5.02386 13.3847 5.95788 14.0174 6.6654C11.8189 7.70234 10.2474 9.85006 10.0267 12.3847C9.2932 11.5366 8.20927 11 7 11C4.79086 11 3 12.7909 3 15C3 16.0239 3.38468 16.9579 4.01739 17.6654C1.64307 18.7853 0 21.2008 0 24H2C2 21.2386 4.23858 19 7 19C9.76142 19 12 21.2386 12 24H14C14 21.2008 12.3569 18.7853 9.98261 17.6654C10.6153 16.9579 11 16.0239 11 15C11 14.2714 10.8052 13.5883 10.4649 13H12C12 10.2386 14.2386 8 17 8C19.7614 8 22 10.2386 22 13H24C24 10.2008 22.3569 7.78529 19.9826 6.6654ZM17 6C18.1046 6 19 5.10457 19 4C19 2.89543 18.1046 2 17 2C15.8954 2 15 2.89543 15 4C15 5.10457 15.8954 6 17 6ZM9 15C9 16.1046 8.10457 17 7 17C5.89543 17 5 16.1046 5 15C5 13.8954 5.89543 13 7 13C8.10457 13 9 13.8954 9 15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
