import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SubscriptionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M21 19L21 10C21 8.89543 20.1046 8 19 8L10 8C8.89543 8 8 8.89543 8 10L8 19C8 20.1046 8.89543 21 10 21L19 21C20.1046 21 21 20.1046 21 19Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M5 15L4 15C2.89543 15 2 14.1046 2 13L2 4C2 2.89543 2.89543 2 4 2L13 2C14.1046 2 15 2.89543 15 4L15 5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M11 14.5L13 16.5L17.5 12"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
