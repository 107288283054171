import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { fetch } from "../utils/dataAccess";
import { TableData } from "../types/Table";
import { TeamMember } from "../types/TeamMember";
import { filterNullValues } from "../utils/object";
import { User } from "../types/User";
import ViolationError from "../utils/violationError";

type ITeamMemberPaginationQueryParams = {
  page?: number;
  limit?: number;
} & { user: User };

type TeamMemberCommonProps = {
  teamMemberId: string;
};

type TeamMemberBlockProps = TeamMemberCommonProps;
type TeamMemberResendInviteProps = TeamMemberCommonProps;
type TeamMemberRequestAccessProps = {
  email: string;
};
type TeamMemberChangeRoleProps = TeamMemberCommonProps & {
  role: number;
};

type TeamMemberInviteProps = {
  firstName: string;
  lastName: string;
  email: string;
  role: number;
};

export const getTeamMembersPagination = async ({
  page,
  limit,
  user,
}: ITeamMemberPaginationQueryParams): Promise<TableData<TeamMember>> => {
  const filteredPropsObj = filterNullValues({
    page: (page || 0) + 1,
    itemsPerPage: limit,
    isBlocked: false,
    "order[id]": "DESC",
  });
  const queryParams = new URLSearchParams(filteredPropsObj);
  const { data } = await fetch({
    url: `/users?${queryParams.toString()}`,
  });

  data["hydra:totalItems"] -= 1;

  const items = (data["hydra:member"] || [])
    .map((rawData: any) => TeamMember.fromRawData(rawData))
    .filter((teamMember: TeamMember) => teamMember.id !== user.id);

  return {
    items: items,
    rowItems: items.map((item: TeamMember) => item.transformToTableView()),
    total: data["hydra:totalItems"] || 0,
    page: +queryParams.get("page")!,
    limit: +queryParams.get("limit")!,
  };
};

export const useTeamMembersPaginationQuery = (
  props: ITeamMemberPaginationQueryParams
): UseQueryResult<TableData<TeamMember>, Error> => {
  return useQuery(["teamMembersPagination", props], () =>
    getTeamMembersPagination(props)
  );
};

export const inviteTeamMember = async (
  values: TeamMemberInviteProps
): Promise<TeamMember> => {
  const { data } = await fetch({
    url: "/users/invite-member",
    method: "POST",
    data: values,
  });
  return data;
};

export const useInviteTeamMemberPost = (): UseMutationResult<
  TeamMember,
  Error | ViolationError,
  TeamMemberInviteProps
> => {
  return useMutation(inviteTeamMember);
};

export const blockTeamMember = async ({
  teamMemberId,
}: TeamMemberBlockProps): Promise<TeamMember> => {
  const { data } = await fetch({
    url: `/users/${teamMemberId}/block`,
    method: "POST",
  });
  return data;
};

export const useBlockTeamMemberPost = (): UseMutationResult<
  TeamMember,
  Error | ViolationError,
  TeamMemberBlockProps
> => {
  return useMutation(blockTeamMember);
};

export const teamMemberResendInvite = async ({
  teamMemberId,
}: TeamMemberResendInviteProps): Promise<TeamMember> => {
  const { data } = await fetch({
    url: `/users/${teamMemberId}/invite-resend`,
    method: "POST",
  });
  return data;
};

export const useTeamMemberResendInvitePost = (): UseMutationResult<
  TeamMember,
  Error | ViolationError,
  TeamMemberResendInviteProps
> => {
  return useMutation(teamMemberResendInvite);
};

export const teamMemberRequestAccess = async ({
  email,
}: TeamMemberRequestAccessProps): Promise<TeamMember> => {
  const { data } = await fetch({
    url: `/users/request-access`,
    method: "POST",
    data: { email },
  });
  return data;
};

export const useRequestAccessPost = (): UseMutationResult<
  TeamMember,
  Error | ViolationError,
  TeamMemberRequestAccessProps
> => {
  return useMutation(teamMemberRequestAccess);
};

export const teamMemberChangeRole = async ({
  teamMemberId,
  role,
}: TeamMemberChangeRoleProps): Promise<TeamMember> => {
  const { data } = await fetch({
    url: `/users/${teamMemberId}/change-role`,
    method: "POST",
    data: { role },
  });
  return data;
};

export const useTeamMemberChangeRolePost = (): UseMutationResult<
  TeamMember,
  Error | ViolationError,
  TeamMemberChangeRoleProps
> => {
  return useMutation(teamMemberChangeRole);
};
