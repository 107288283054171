import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import {
  PATH_AFTER_LOGIN,
  PATH_AUTH,
  PATH_CONNECTOR_REDIRECT_URI,
  PATH_DASHBOARD,
  PATH_ONBOARDING,
  PATH_PAYMENTS,
  PATH_ERAD_CARD,
  PATH_SETTINGS,
  PATH_CAPITAL,
  PATH_DEPLOYMENT,
} from "../components/Routes/paths";
import useAuth from "../hooks/useAuth";
import { getActivePath, getExactPath } from "../utils/route";

const OnboardingGuard = ({ children }: { children?: JSX.Element }) => {
  const { user, company, isCompanyInitialized } = useAuth();
  let { pathname, search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const email = searchParams.get("email");

  useEffect(() => {
    if (email) {
      sessionStorage.setItem("email", email);
    }
  }, [email]);

  const storedEmail = sessionStorage.getItem("email");

  if (!isCompanyInitialized) {
    return <LoadingScreen />;
  }

  if (company?.kycKybStatus === "verified") {
    if (getActivePath(PATH_DEPLOYMENT.root, pathname)) {
      return children || <Outlet />;
    }

    if (getActivePath(PATH_CAPITAL.root, pathname)) {
      return children || <Outlet />;
    }

    return <Navigate to={PATH_CAPITAL.root} />;
  }

  // Do not redirect users when they are back from connector redirect uri.
  if (
    getActivePath(PATH_CONNECTOR_REDIRECT_URI.root, pathname) ||
    getActivePath(PATH_ONBOARDING.root, pathname) ||
    getActivePath(PATH_PAYMENTS.root, pathname) ||
    getActivePath(PATH_ERAD_CARD.root, pathname)
  ) {
    return children || <Outlet />;
  }

  // Check if we need to redirect to one of the onboarding steps.
  let onboardingStep;
  if (!user?.isPhoneVerified) {
    onboardingStep = PATH_AUTH.verifyPhone;
  } else if (
    !company?.isReadyToConnectData &&
    company?.kycKybStatus !== "provided"
  ) {
    onboardingStep = PATH_AUTH.tellAboutBusiness;
  } else if (storedEmail) {
    onboardingStep = PATH_SETTINGS.team + "?email=" + storedEmail;
  } else if (company?.kycKybStatus !== "verified") {
    // If user wants to load step 1, we allow.
    if (
      getActivePath(PATH_AUTH.tellAboutBusiness, pathname) ||
      getActivePath(PATH_ONBOARDING.root, pathname)
    ) {
      return children || <Outlet />;
    }

    if (
      getActivePath(PATH_CAPITAL.root, pathname) &&
      company?.kycKybStatus === "provided"
    ) {
      if (!company?.systemsConnectedDate) {
        onboardingStep = PATH_ONBOARDING.applicationOverview;
      } else {
        onboardingStep = PATH_ONBOARDING.underReview;
      }
    } else if (!company?.areSystemsConnected) {
      if (!getActivePath(PATH_ONBOARDING.connectSystems, pathname)) {
        onboardingStep = PATH_ONBOARDING.connectSystems;
      }
    } else if (!getActivePath(PATH_DASHBOARD.root, pathname)) {
      if (company?.kycKybStatus === "not_provided") {
        if (!getActivePath(PATH_ONBOARDING.connectSystems, pathname)) {
          onboardingStep = PATH_ONBOARDING.verification;
        }
      } else if (!company?.systemsConnectedDate) {
        onboardingStep = PATH_ONBOARDING.applicationOverview;
      } else if (company?.kycKybStatus !== "verified") {
        onboardingStep = PATH_ONBOARDING.underReview;
      }
    }
  } else if (getActivePath(PATH_ONBOARDING.root, pathname)) {
    onboardingStep = PATH_AFTER_LOGIN;
  }

  // Do the actual redirect if we need so.
  if (onboardingStep && !getExactPath(onboardingStep, pathname)) {
    return <Navigate to={onboardingStep} />;
  }

  return children || <Outlet />;
};

export default OnboardingGuard;
