import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { isLngAR } from "../../utils/i18n";

const RTL = (props: any) => {
  const cacheLtr = createCache({
    key: "muiltr",
  });
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  return (
    <CacheProvider value={isLngAR() ? cacheRtl : cacheLtr}>
      {props.children}
    </CacheProvider>
  );
};
export default RTL;
