import Page from "./index";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ENTRYPOINT } from "../../config/entrypoint";
import { PATH_AUTH } from "../Routes/paths";

export default function EventRedirect() {
  const { eventCode } = useParams();
  const eventUniqueCode = eventCode?.split("_")[1] || eventCode;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    fetch(`${ENTRYPOINT}/get/event-detail?code=${eventUniqueCode}`, {
      method: "GET",
      headers: new Headers({
        accept: "application/ld+json",
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        navigate(
          PATH_AUTH.register +
            `?website=1&lng=${data.event.language}&p=${data.event.partnerName}&t=${data.event.uniqueCode}`
        );
      })
      .catch((error) => {
        navigate(PATH_AUTH.register);
      });
  }, []);

  return <Page title={t("global.loading")}>{t("global.loading")}</Page>;
}
