import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2361_28383)">
        <circle cx="12" cy="7.5" r="6" stroke="currentColor" strokeWidth="2" />
        <path
          d="M23 24.5C23 18.4249 18.0751 13.5 12 13.5C5.92487 13.5 1 18.4249 1 24.5"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_2361_28383">
          <rect width="24" height="24" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
