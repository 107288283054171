import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { getActivePath } from "../../../../utils/route";
import {
  CapitalIcon,
  HistoryIcon,
  OnboardingIcon,
  PaymentIcon,
  EradCardIcon,
} from "../../../Icons";
import {
  PATH_CAPITAL,
  PATH_STATEMENT,
  PATH_ONBOARDING,
  PATH_PAYMENTS,
  PATH_ERAD_CARD,
} from "../../../Routes/paths";
import { ERAD_CARDS_ENABLED } from "../../../../config/config";
import { useApiTokensQuery } from "../../../../queries/eradPay";
import { useJune } from "../../../../hooks/useJune";
import { useCallback } from "react";

export default function Menu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { company, user } = useAuth();
  const { data: apiTokens, isLoading: isApiTokensLoading } =
    useApiTokensQuery();
  const hasApiTokensToUse = apiTokens && apiTokens.length > 0;
  const analytics = useJune(process.env.REACT_APP_JUNE_WRITE_KEY || "");
  const trackEvent = useCallback(
    (data) => {
      if (analytics) analytics.track(data);
    },
    [analytics]
  );
  return (
    <Box sx={{ mt: { sm: 4 } }}>
      <List>
        {/*
        Hid temporarily
        {company?.areSystemsConnected && (
          <ListItemButton
            component={RouterLink}
            to={PATH_DASHBOARD.root}
            selected={getActivePath(PATH_DASHBOARD.root, pathname)}
            sx={{ mb: 1.5 }}
          >
            <ListItemIcon>
              <DashboardIcon fill="none" sx={{ fill: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.main.dashboard")}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        )} */}

        {company?.kycKybStatus !== "verified" && (
          <ListItemButton
            component={RouterLink}
            to={PATH_ONBOARDING.root}
            selected={getActivePath(PATH_ONBOARDING.root, pathname)}
            sx={{ mb: 1.5 }}
          >
            <ListItemIcon>
              <OnboardingIcon fill="none" sx={{ fill: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.main.capital")}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        )}

        {company?.kycKybStatus === "verified" && (
          <ListItemButton
            component={RouterLink}
            to={PATH_CAPITAL.root}
            selected={getActivePath(PATH_CAPITAL.root, pathname)}
            sx={{ mb: 1.5 }}
          >
            <ListItemIcon>
              <CapitalIcon fill="none" sx={{ fill: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.main.capital")}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        )}

        <ListItemButton
          component={RouterLink}
          to={PATH_STATEMENT.root}
          onClick={() =>
            trackEvent({
              userId: user?.email,
              event: "Statement visited",
              context: {
                groupId: company?.companyNo,
              },
            })
          }
          selected={getActivePath(PATH_STATEMENT.root, pathname)}
          sx={{ mb: 1.5 }}
        >
          <ListItemIcon>
            <HistoryIcon fill="none" />
          </ListItemIcon>
          <ListItemText
            primary={t("menu.main.statement")}
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </ListItemButton>

        {!isApiTokensLoading && hasApiTokensToUse && (
          <ListItemButton
            component={RouterLink}
            to={PATH_PAYMENTS.root}
            selected={getActivePath(PATH_PAYMENTS.root, pathname)}
            sx={{ mb: 1.5 }}
          >
            <ListItemIcon>
              <PaymentIcon fill="none" sx={{ fill: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.main.payments")}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        )}

        {ERAD_CARDS_ENABLED && (
          <ListItemButton
            component={RouterLink}
            to={PATH_ERAD_CARD.root}
            selected={getActivePath(PATH_ERAD_CARD.root, pathname)}
          >
            <ListItemIcon>
              <EradCardIcon fill="none" sx={{ fill: "none" }} />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.main.cards")}
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItemButton>
        )}
      </List>
    </Box>
  );
}
