export default class ViolationError extends Error {
  defaultErrorMsg: string;
  status: string;
  fields: { [key: string]: string };
  payload?: { [key: string]: Record<string, any> };

  constructor(
    message: string,
    status: string,
    fields: { [key: string]: string },
    payload?: { [key: string]: Record<string, any> }
  ) {
    super("Submit Validation Failed");
    this.defaultErrorMsg = message;
    this.status = status;
    this.fields = fields;
    this.payload = payload;
  }
}

interface ErrorMap {
  [key: string]: string;
}
export const getPasswordErrorMsg = (error: string) => {
  const errorMap: ErrorMap = {
    // 'indicator keyword' : 'translation key'
    digit: "onedigit",
    alphabetical: "oneAlphabet",
    special: "oneSpecialChar",
    more: "max",
    less: "min",
    wrong: "invalid",
  };
  for (const keyword in errorMap) {
    if (error.includes(keyword)) {
      return errorMap[keyword];
    }
  }
  return "required";
};
