import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SettlementsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_6361_959)">
        <path
          d="M5 8L5 19C5 20.1046 5.89543 21 7 21L20 21M4 3L17 3C18.1046 3 19 3.89543 19 5L19 16"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M-1.10802e-07 3.00006L4 0.465205L4 5.53491L-1.10802e-07 3.00006Z"
          fill="currentColor"
        />
        <path
          d="M9 11.5L11 13.5L15.5 9"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M24 21.0001L20 18.4652L20 23.5349L24 21.0001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6361_959">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
