import { Logout as LogoutIcon, Person } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import {
  HEADER,
  LANGUAGE_SWITCHER_ENABLED,
  NAVBAR,
} from "../../../../config/config";
import useAuth from "../../../../hooks/useAuth";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import useTitle from "../../../../hooks/useTitle";
import LanguageSwitcher from "../../../LanguageSwitcher";
import { PATH_SETTINGS } from "../../../Routes/paths";
import InviteNewMemberModal from "../../../TeamSettings/InviteNewMemberModal";

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  [theme.breakpoints.up("lg")]: {
    height: HEADER.MOBILE_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    marginLeft: `${NAVBAR.DASHBOARD_WIDTH + 1}px`,
  },
}));

export default function Header() {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { title } = useTitle();
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const isMountedRef = useIsMountedRef();
  const [inviteMemberModalOpened, setInviteMemberModalOpened] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    if (isMountedRef.current) {
      handleClose();
    }
  };

  return (
    <RootStyle color="inherit" elevation={0} position="relative">
      <Toolbar
        sx={{
          minHeight: "100% !important",
          justifyContent: "space-between",
          px: { lg: 5 },
        }}
      >
        <Typography variant="h4" component="h1">
          {title}
        </Typography>

        <Box display={"flex"} alignItems={"center"}>
          {user?.roleHuman === "administrator" && (
            <Button
              sx={{ m: 3, borderRadius: "100px", height: "30px" }}
              variant="outlined"
              onClick={() => {
                setInviteMemberModalOpened(true);
              }}
            >
              {t("pages.team.invite_member_modal.button")}
            </Button>
          )}

          {LANGUAGE_SWITCHER_ENABLED && <LanguageSwitcher />}
          <Button onClick={handleOpen}>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Avatar sx={{ width: 32, height: 32 }}>
                <Person sx={{ color: "#fff" }} />
              </Avatar>

              <Typography variant="body2">{`${user?.firstName} ${user?.lastName}`}</Typography>
            </Stack>
          </Button>
          <Popover
            id="account-appbar"
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={1}
          >
            <List sx={{ p: 3 }}>
              <ListItemButton
                component={RouterLink}
                to={PATH_SETTINGS.root}
                sx={{ mb: 1.5 }}
                onClick={handleClose}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("menu.account.settings")}
                  primaryTypographyProps={{ variant: "h5" }}
                />
              </ListItemButton>

              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t("global.logout")}
                  primaryTypographyProps={{ variant: "h5" }}
                />
              </ListItemButton>
            </List>
          </Popover>
        </Box>
      </Toolbar>

      <InviteNewMemberModal
        opened={inviteMemberModalOpened}
        setOpened={setInviteMemberModalOpened}
      />
    </RootStyle>
  );
}
