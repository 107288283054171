import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  PATH_AFTER_LOGIN,
  PATH_ONBOARDING,
  PATH_AUTH,
  PATH_DEPLOYMENT,
  PATH_CONNECTOR_REDIRECT_URI,
  PATH_ERAD_CARD,
  PATH_PAYMENTS,
  PATH_STATEMENT,
  PATH_SETTINGS,
} from "../components/Routes/paths";
import useAuth from "../hooks/useAuth";
import { getActivePath, getExactPath } from "../utils/route";
const DeploymentGuard = ({ children }: { children?: JSX.Element }) => {
  const { company } = useAuth();

  let { pathname } = useLocation();

  if (
    getActivePath(PATH_CONNECTOR_REDIRECT_URI.root, pathname) ||
    getActivePath(PATH_AUTH.tellAboutBusiness, pathname) ||
    getActivePath(PATH_AUTH.verifyPhone, pathname) ||
    getActivePath(PATH_ONBOARDING.root, pathname) ||
    getActivePath(PATH_PAYMENTS.root, pathname) ||
    getActivePath(PATH_ERAD_CARD.root, pathname) ||
    getActivePath(PATH_STATEMENT.root, pathname) ||
    getActivePath(PATH_SETTINGS.root, pathname)
  ) {
    return children || <Outlet />;
  }
  // Check if we need to redirect to one of the onboarding steps.
  let deploymentStep;

  if (company?.offerAccepted) {
    if (
      company?.postOfferKycKybStatus === "verified" ||
      company.hasActiveLoan
    ) {
      deploymentStep = PATH_AFTER_LOGIN;
    } else if (company?.postOfferKycKybStatus === "provided") {
      if (
        !getActivePath(PATH_DEPLOYMENT.submitKyc, pathname) &&
        !getActivePath(PATH_DEPLOYMENT.signContract, pathname)
      ) {
        deploymentStep = PATH_DEPLOYMENT.signContract;
      }
    } else {
      deploymentStep = PATH_DEPLOYMENT.submitKyc;
    }
  } else {
    deploymentStep = PATH_AFTER_LOGIN;
  }

  // Do the actual redirect if we need so.
  if (deploymentStep && !getExactPath(deploymentStep, pathname)) {
    return <Navigate to={deploymentStep} replace={true} />;
  }

  return children || <Outlet />;
};

export default DeploymentGuard;
