import {
  alpha,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { PATH_STATEMENT } from "../Routes/paths";
import { EradLMSLedger } from "../../types/EradLMSLedger";
import { useTranslation } from "react-i18next";

import { isLngAR } from "../../utils/i18n";
import Price from "../Currency/CurrencyPrice";

type FinancingOfferDetailProps = {
  ledger: EradLMSLedger;
};

const FinancingOfferDetail = ({ ledger }: FinancingOfferDetailProps) => {
  const { t } = useTranslation();

  // Fixing potential missing values in the ledger.
  if (ledger.paid === 0 && ledger.dueLoanAmount - ledger.outstanding > 0) {
    ledger.paid = ledger.dueLoanAmount - ledger.outstanding;
  }
  if (
    (ledger.outstanding === 0 && ledger.dueLoanAmount - ledger.paid > 0) ||
    ledger.outstanding > ledger.dueLoanAmount
  ) {
    ledger.outstanding = ledger.dueLoanAmount - ledger.paid;
  }

  let paidPercentage = Math.round(
    ((Number(ledger.dueLoanAmount) - Number(ledger.outstanding)) /
      Number(ledger.dueLoanAmount)) *
      100
  );
  paidPercentage = paidPercentage <= 0 ? 0 : paidPercentage;
  const outStandingPercentage = 100 - paidPercentage;

  return (
    <Card
      key={ledger.ledgerID}
      sx={{
        mb: 4,
        height: {
          xs: "auto",
          sm: ledger.minAmount && ledger.maxAmount ? "327px" : "283px",
        },
      }}
    >
      <CardContent
        sx={{
          pt: { xs: 2, sm: ledger.minAmount && ledger.maxAmount ? 12 : 9 },
          px: { xs: 2, sm: 4 },
          "&:last-child": { pb: 5 },
          position: "relative",
        }}
      >
        <Stack
          direction="row-reverse"
          sx={{
            position: "absolute",
            right: (theme: Theme) => theme.spacing(5),
            display: { xs: "none", sm: "block" },
          }}
        >
          <Button
            id="view-details"
            type="submit"
            variant="outlined"
            size="medium"
            component={RouterLink}
            to={`${PATH_STATEMENT.root}?expand=${ledger.ledgerID}`}
            endIcon={isLngAR() ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          >
            {t("global.view_details")}
          </Button>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 7 }}
        >
          <Box>
            <Typography variant="h2" component="div" sx={{ mb: 4 }}>
              <Price
                price={Number(ledger.dueLoanAmount)}
                currency={ledger.currency}
              />
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 7 }}
        >
          {paidPercentage === 0 ? (
            <Stack direction="row" flexGrow={1}>
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: 45,
                    backgroundColor: "primary.main",
                    mb: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopLeftRadius: "6px",
                    ...(paidPercentage === 0 || paidPercentage === 100
                      ? {
                          borderBottomRightRadius: "6px",
                          borderTopRightRadius: "6px",
                        }
                      : {}),
                  }}
                ></Box>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ mb: "6px" }}
                    >
                      <Price
                        price={Number(ledger.paid || 0)}
                        currency={ledger.currency!}
                      />
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    sx={{
                      color: (theme) => alpha(theme.palette.text.primary, 0.3),
                    }}
                  >
                    {t("global.paid")}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          ) : (
            <Stack direction="row" flexGrow={1}>
              {paidPercentage > 0 && (
                <Box
                  width={`${paidPercentage}%`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: paidPercentage < 20 ? "flex-start" : "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "primary.main",
                      mb: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopLeftRadius: "6px",
                      ...(paidPercentage === 0 || paidPercentage === 100
                        ? {
                            borderBottomRightRadius: "6px",
                            borderTopRightRadius: "6px",
                          }
                        : {}),
                    }}
                  ></Box>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        component="div"
                        sx={{ mb: "6px" }}
                      >
                        <Price
                          price={Number(ledger.paid || 0)}
                          currency={ledger.currency!}
                        />
                      </Typography>
                    </Box>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      sx={{
                        color: (theme) =>
                          alpha(theme.palette.text.primary, 0.3),
                      }}
                    >
                      {t("global.paid")}
                    </Typography>
                  </Stack>
                </Box>
              )}
              {outStandingPercentage > 0 && Number(ledger.outstanding) && (
                <Box
                  width={`${outStandingPercentage}%`}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: 45,
                      backgroundColor: "text.primary",
                      mb: "6px",
                      borderBottomRightRadius: "6px",
                      borderTopRightRadius: "6px",
                      ...(outStandingPercentage === 100
                        ? {
                            borderBottomLeftRadius: "6px",
                            borderTopLeftRadius: "6px",
                          }
                        : {}),
                    }}
                  ></Box>
                  <Box>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{ mb: "6px" }}
                    >
                      <Price
                        price={Number(ledger.outstanding)}
                        currency={ledger.currency!}
                      />
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="text.secondary"
                    sx={{
                      color: (theme) => alpha(theme.palette.text.primary, 0.3),
                    }}
                  >
                    {t("global.outstanding")}
                  </Typography>
                </Box>
              )}
            </Stack>
          )}

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ display: { xs: "none", sm: "block" } }}
          />
          <Divider sx={{ my: 2, display: { xs: "block", sm: "none" } }} />
          <Box sx={{ textAlign: "center", mt: { xs: 2, sm: 0 } }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {t("pages.capital.next_repayment_date")}
            </Typography>
            {ledger.nextRepaymentDate ? (
              <>
                <Typography variant="h1" component="p">
                  {format(new Date(ledger?.nextRepaymentDate), "dd")}
                </Typography>
                <Typography variant="h4" component="p" color="text.secondary">
                  {format(new Date(ledger?.nextRepaymentDate), "MMM")}
                </Typography>
              </>
            ) : (
              <Typography variant="h1" component="p">
                -
              </Typography>
            )}
          </Box>
        </Stack>
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button
            id="view-details-mobile"
            type="submit"
            variant="outlined"
            size="medium"
            component={RouterLink}
            to={`${PATH_STATEMENT.root}?expand=${ledger.ledgerID}`}
            endIcon={isLngAR() ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            sx={{ width: "100%", height: "auto", py: 2 }}
          >
            {t("global.view_details")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FinancingOfferDetail;
