import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CreditCardMadaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="32"
        height="25"
        viewBox="0 0 32 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="11" fill="#479ED9" />
        <rect y="14" width="32" height="11" fill="#8DBA38" />
      </svg>
    </SvgIcon>
  );
}
