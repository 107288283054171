import {
  Button,
  Card,
  Container,
  Stack,
  Typography,
  Box,
  LinearProgress,
  CircularProgress,
  Alert,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATH_DEPLOYMENT } from "../Routes/paths";
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import { KycKybValues } from "../OnboardingVerification/KycKybForm";
import useAuth from "../../hooks/useAuth";
import { useKycKybPost } from "../../queries/kycKyb";
import { useEditCompanyCountry } from "../../queries/company";
import { PostDeploymentValues } from "../../types/Company";
import * as Yup from "yup";
import { KycKybDocumentsValues } from "../../types/FormValues";
import {
  DEFAULT_ALLOWED_MIME_TYPES,
  DEFAULT_CERTIFICATION_MIME_TYPES,
} from "../../config/config";
import { PrivateFile } from "../../types/PrivateFile";
import { UploadMultiFile } from "../Upload";
import ViolationError from "../../utils/violationError";
import PoWNotificationModal from "../OnboardingVerification/PoWNotificationModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import { placement } from "../../utils/i18n";
import { CompanyShareholder } from "../../types/Company";
import { PreDeploymentValues } from "../../types/Company";
import { useEditCompanyPostOfferKycDocuments } from "../../queries/company";
interface IconStatusProps {
  data: any;
  form_error: boolean;
}
export const IconStatus = ({ data, form_error }: IconStatusProps) => {
  if (form_error === true) return <WarningAmberOutlinedIcon color="warning" />;
  if (data && data.length) return <CheckCircleOutlineIcon color="success" />;
  else return <InfoOutlinedIcon color="info" />;
};
const validateOwnershipPercentage = (
  shareholders: Array<CompanyShareholder> | undefined
) => {
  let totalScore = 0;
  if (shareholders) {
    shareholders.forEach((item) => {
      if (item.ownership) totalScore += item.ownership;
    });
  }

  if (totalScore > 100) {
    return false;
  }
  return true;
};

const DeploymentSubmitDocuments = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, company, setCompany } = useAuth();
  const countryCode = company?.countryCode;
  const { mutate: kycKybPost } = useKycKybPost();
  const { mutate: editCompanyCountry } = useEditCompanyCountry();
  const { mutate: editCompanyPostOfferKycDoc } =
    useEditCompanyPostOfferKycDocuments();
  const [PoWModalOpened, setPoWModalOpened] = useState(false);
  const initialValues = {
    proofOfAddress: company?.kycKybDocuments?.proofOfAddress ?? [],
    articlesOfAssociation:
      company?.kycKybDocuments?.articlesOfAssociation ?? [],
    certificateOfIncorporation:
      company?.kycKybDocuments?.certificateOfIncorporation ?? [],
    memorandumOfAssociation:
      company?.kycKybDocuments?.memorandumOfAssociation ?? [],
  } as KycKybValues;
  const shareholders = company?.companyShareholders ?? [];
  const directors = company?.companyDirectors ?? [];
  const combinedInitialValues = {
    ...initialValues,
    shareholders: shareholders,
    directors: directors,
  };
  // Recieve list of private file, transfor to list of private file name only
  const transformData = (data: PreDeploymentValues) => {
    return {
      companyShareholders: data.companyShareholders?.map((shareholder) => ({
        firstName: shareholder.firstName,
        lastName: shareholder.lastName,
        ownership: shareholder.ownership,
        passport: shareholder.passport?.map((passport) => passport["@id"]),
        id: shareholder.id,
      })),
      companyDirectors: data.companyDirectors?.map((director) => ({
        firstName: director.firstName,
        lastName: director.lastName,
        passport: director.passport?.map((passport) => passport["@id"]),
        residentId: director.residentId?.map((resId) => resId["@id"]),
        id: director.id,
      })),
    } as PostDeploymentValues;
  };

  return (
    <Container maxWidth="lg" sx={{ my: 5, pr: 0 }} disableGutters>
      <Card sx={{ mb: 6, p: 4 }}>
        <Stack direction="column" spacing={9} justifyContent="space-between">
          <Box>
            <Typography variant="h4" sx={{ mb: 1.5 }}>
              {t("pages.deployment.heading")}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t("pages.deployment.subheading")}
            </Typography>
          </Box>

          <Formik
            validateOnMount={true}
            initialValues={combinedInitialValues}
            validationSchema={Yup.object().shape({
              proofOfAddress: Yup.array()
                .required(
                  t("error.company.kyc_kyb_documents.proof_of_address.required")
                )
                .min(1, () => {
                  return t(
                    "error.company.kyc_kyb_documents.proof_of_address.required"
                  );
                })
                .max(20, () => {
                  return t(
                    "error.company.kyc_kyb_documents.proof_of_address.max",
                    {
                      limit: 20,
                    }
                  );
                })
                .mime(
                  Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(", "),
                  })
                ),
              articlesOfAssociation: Yup.array()
                .required(
                  t(
                    "error.company.kyc_kyb_documents.articles_of_association.required"
                  )
                )
                .min(1, () => {
                  return t(
                    "error.company.kyc_kyb_documents.articles_of_association.required"
                  );
                })
                .max(20, () => {
                  return t(
                    "error.company.kyc_kyb_documents.articles_of_association.max",
                    {
                      limit: 20,
                    }
                  );
                })
                .mime(
                  Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                      ", "
                    ),
                  })
                ),
              memorandumOfAssociation: Yup.array()
                .required(
                  t(
                    "error.company.kyc_kyb_documents.memorandum_of_association.required"
                  )
                )
                .min(1, () => {
                  return t(
                    "error.company.kyc_kyb_documents.memorandum_of_association.required"
                  );
                })
                .max(20, () => {
                  return t(
                    "error.company.kyc_kyb_documents.memorandum_of_association.max",
                    {
                      limit: 20,
                    }
                  );
                })
                .mime(
                  Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                      ", "
                    ),
                  })
                ),
              certificateOfIncorporation: Yup.array()
                .max(20, () => {
                  return t(
                    "error.company.kyc_kyb_documents.certificate_of_incorporation.max",
                    {
                      limit: 20,
                    }
                  );
                })
                .mime(
                  Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES),
                  t("error.general.mime", {
                    types: Object.keys(DEFAULT_CERTIFICATION_MIME_TYPES).concat(
                      ", "
                    ),
                  })
                ),
              directors: Yup.array()
                .required(t("error.company.directors.required"))
                .min(1, () => {
                  return t("error.company.directors.required");
                })
                .of(
                  Yup.object().shape({
                    firstName: Yup.string().required("First name is required"),
                    lastName: Yup.string().required("Last name is required"),
                    passport: Yup.array()
                      .required(t("error.company.directors.required"))
                      .min(1, () => {
                        return t("error.company.directors.passport.min");
                      })
                      .max(20, () => {
                        return t("error.company.directors.passport.max", {
                          limit: 20,
                        });
                      })
                      .mime(
                        Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
                        t("error.general.mime", {
                          types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(
                            ", "
                          ),
                        })
                      ),
                    residentId: Yup.array()
                      .required(
                        t("error.company.directors.residentId.required")
                      )
                      .min(1, () => {
                        return t("error.company.directors.residentId.min");
                      })
                      .max(20, () => {
                        return t("error.company.directors.residentId.max", {
                          limit: 20,
                        });
                      })
                      .mime(
                        Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
                        t("error.general.mime", {
                          types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(
                            ", "
                          ),
                        })
                      ),
                  })
                ),
              shareholders: Yup.array()
                .required(t("error.company.shareholders.required"))
                .min(1, () => {
                  return t("error.company.shareholders.required");
                })
                .of(
                  Yup.object().shape({
                    firstName: Yup.string().required("First name is required"),
                    lastName: Yup.string().required("Last name is required"),
                    ownership: Yup.number()
                      .required("Ownership is required")
                      .positive("Must be positive")
                      .integer("Must be an integer")
                      .max(100, "Max value is 100"),
                    passport: Yup.array()
                      .required(t("error.company.shareholders.required"))
                      .min(1, () => {
                        return t("error.company.shareholders.passport.min");
                      })
                      .max(20, () => {
                        return t("error.company.shareholders.passport.max", {
                          limit: 20,
                        });
                      })
                      .mime(
                        Object.keys(DEFAULT_ALLOWED_MIME_TYPES),
                        t("error.general.mime", {
                          types: Object.keys(DEFAULT_ALLOWED_MIME_TYPES).concat(
                            ", "
                          ),
                        })
                      ),
                  })
                ),
            })}
            onSubmit={(
              values,
              { setStatus, setErrors, setFieldError, setSubmitting }
            ) => {
              if (validateOwnershipPercentage(values.shareholders) === false) {
                setStatus({
                  isValid: false,
                  msg: t("error.company.shareholders.ownership.max"),
                });
                values.shareholders.forEach((shareholder, index) => {
                  setFieldError(
                    `shareholders[${index}].ownership`,
                    t("error.company.shareholders.ownership.max")
                  );
                });
                setSubmitting(false);
                return;
              }

              const valuesKycKybonly = {
                proofOfAddress: values.proofOfAddress,
                articlesOfAssociation: values.articlesOfAssociation,
                certificateOfIncorporation: values.certificateOfIncorporation,
                memorandumOfAssociation: values.memorandumOfAssociation,
              };
              const postDeploymentUpdate = {
                companyShareholders: values.shareholders,
                companyDirectors: values.directors,
              };

              const filteredValues = {} as KycKybDocumentsValues;
              for (const [documentGroup, documents] of Object.entries(
                valuesKycKybonly
              )) {
                filteredValues[documentGroup as keyof KycKybValues] =
                  documents.map((document: PrivateFile) => document["@id"]!);
              }

              kycKybPost(
                { companyIri: user?.company!, values: filteredValues },
                {
                  onError: (error) => {
                    if (error instanceof ViolationError) {
                      setStatus({
                        isValid: false,
                        msg: `${error.defaultErrorMsg}`,
                      });
                      setErrors(error.fields);
                    } else {
                      setStatus({
                        isValid: false,
                        msg: `${error.message}`,
                      });
                    }

                    setSubmitting(false);
                  },
                  onSuccess: (data) => {
                    editCompanyPostOfferKycDoc(
                      {
                        companyIri: data ? data["@id"]! : "",
                        values: transformData(postDeploymentUpdate),
                      },
                      {
                        onSuccess: () => {
                          editCompanyCountry(
                            {
                              companyIri: data ? data["@id"]! : "",
                              values: {
                                countryCode: countryCode,
                                postOfferKycKybStatus: "provided",
                              },
                            },
                            {
                              onSuccess: async (updated_company) => {
                                setCompany(updated_company);
                                navigate(PATH_DEPLOYMENT.signContract);
                              },
                            }
                          );
                        },
                        onError: (error) => {
                          setStatus({
                            isValid: false,
                            msg: `${error.message}`,
                          });
                          setSubmitting(false);
                        },
                      }
                    );
                  },
                }
              );
            }}
          >
            {({ values, errors, isSubmitting, isValid, status }) => (
              <Form>
                <PoWNotificationModal
                  opened={PoWModalOpened}
                  setOpened={setPoWModalOpened}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.articlesOfAssociation}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.articles_of_association.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t(
                        "pages.verification.articles_of_association.subheading"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="articlesOfAssociation"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValues.articlesOfAssociation}
                    />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.memorandumOfAssociation}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t(
                        "pages.verification.memorandum_of_association.heading"
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t(
                        "pages.verification.memorandum_of_association.subheading"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="memorandumOfAssociation"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValues.memorandumOfAssociation}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <IconStatus
                      data={values.certificateOfIncorporation}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t(
                        "pages.verification.certificate_of_incorporation.heading"
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t(
                        "pages.verification.certificate_of_incorporation.subheading"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="certificateOfIncorporation"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_CERTIFICATION_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValues.certificateOfIncorporation}
                    />
                  </Box>
                </Stack>
                {/* Directors */}
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ my: 4 }}
                >
                  <Box>
                    <IconStatus
                      data={values.directors}
                      form_error={
                        values.directors.length && errors.directors
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.directors.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t("pages.verification.directors.subheading")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <FieldArray name="directors">
                      {({ insert, remove, push }) => (
                        <div>
                          <Box
                            sx={{
                              width: "100%",
                              height: "64px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "12px",
                              border: "1px solid #E0E0E0",
                              px: 3,
                            }}
                          >
                            <Typography>
                              {t(
                                "pages.verification.directors.add_new_btn_text"
                              )}
                            </Typography>
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                push({ firstName: "", lastName: "" })
                              }
                            >
                              {t("global.add")}
                            </Button>
                          </Box>
                          {values.directors.map((person, index) => (
                            <>
                              <div key={index}>
                                <Stack
                                  direction="column"
                                  justifyContent="space-between"
                                  spacing={4}
                                  sx={{
                                    borderRadius: "12px",
                                    border: "1px solid #E0E0E0",
                                    p: 4,
                                    my: 2,
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                  >
                                    <Field
                                      id={`field-firstname-${index}`}
                                      name={`directors[${index}].firstName`}
                                      type="text"
                                      label={t("fields.first_name")}
                                      disabled={isSubmitting}
                                      as={TextField}
                                      variant="standard"
                                      fullWidth
                                    />
                                    <Field
                                      id={`field-lastname-${index}`}
                                      type="text"
                                      name={`directors[${index}].lastName`}
                                      label={t("fields.last_name")}
                                      disabled={isSubmitting}
                                      as={TextField}
                                      variant="standard"
                                      fullWidth
                                    />
                                  </Stack>
                                  <Stack direction="column" spacing={1}>
                                    <Box>
                                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                                        {t(
                                          "pages.verification.directors.passport_heading"
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary", mb: 2 }}
                                      >
                                        {t(
                                          "pages.verification.directors.passport_subheading"
                                        )}
                                      </Typography>
                                    </Box>
                                    <UploadMultiFile
                                      key={index}
                                      sxWrapper={{ mb: 3 }}
                                      fieldName={`directors[${index}].passport`}
                                      disabled={isSubmitting}
                                      acceptedFileTypes={
                                        DEFAULT_ALLOWED_MIME_TYPES
                                      }
                                      filesLimit={20}
                                      isInitialFiles={person.passport}
                                    />
                                  </Stack>
                                  <Stack direction="column" spacing={1}>
                                    <Box>
                                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                                        {t(
                                          "pages.verification.directors.resident_id_heading"
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary", mb: 2 }}
                                      >
                                        {t(
                                          "pages.verification.directors.resident_id_subheading"
                                        )}
                                      </Typography>
                                    </Box>
                                    <UploadMultiFile
                                      key={index}
                                      sxWrapper={{ mb: 3 }}
                                      fieldName={`directors[${index}].residentId`}
                                      disabled={isSubmitting}
                                      acceptedFileTypes={
                                        DEFAULT_ALLOWED_MIME_TYPES
                                      }
                                      filesLimit={20}
                                      isInitialFiles={person.residentId}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </Box>
                </Stack>
                {/* Shareholders */}
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ my: 4 }}
                >
                  <Box>
                    <IconStatus
                      data={values.shareholders}
                      form_error={
                        values.shareholders.length && errors.shareholders
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      {t("pages.verification.shareholders.heading")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t("pages.verification.shareholders.subheading")}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <FieldArray name="shareholders">
                      {({ insert, remove, push }) => (
                        <div>
                          <Box
                            sx={{
                              width: "100%",
                              height: "64px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "12px",
                              border: "1px solid #E0E0E0",
                              px: 3,
                            }}
                          >
                            <Typography>
                              {t(
                                "pages.verification.shareholders.add_new_btn_text"
                              )}
                            </Typography>
                            <Button
                              variant="outlined"
                              startIcon={<AddIcon />}
                              onClick={() =>
                                push({
                                  firstName: "",
                                  lastName: "",
                                  ownership: 0,
                                })
                              }
                            >
                              {t("global.add")}
                            </Button>
                          </Box>
                          {values.shareholders.map((person, index) => (
                            <>
                              <div key={index}>
                                <Stack
                                  direction="column"
                                  justifyContent="space-between"
                                  spacing={4}
                                  sx={{
                                    borderRadius: "12px",
                                    border: "1px solid #E0E0E0",
                                    p: 4,
                                    my: 2,
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                  >
                                    <Field
                                      id={`field-firstname-${index}`}
                                      name={`shareholders[${index}].firstName`}
                                      type="text"
                                      label={t("fields.first_name")}
                                      disabled={isSubmitting}
                                      as={TextField}
                                      variant="standard"
                                      fullWidth
                                    />
                                    <Field
                                      id={`field-lastname-${index}`}
                                      type="text"
                                      name={`shareholders[${index}].lastName`}
                                      label={t("fields.last_name")}
                                      disabled={isSubmitting}
                                      as={TextField}
                                      variant="standard"
                                      fullWidth
                                    />
                                  </Stack>
                                  <Stack>
                                    <Field
                                      id="field-shareholder-ownership"
                                      name={`shareholders[${index}].ownership`}
                                      type="number"
                                      label={t("fields.ownership")}
                                      disabled={isSubmitting}
                                      as={TextField}
                                      variant="standard"
                                    />
                                    <ErrorMessage
                                      name={`shareholders[${index}].ownership`}
                                      render={(msg) => (
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            color: "#FFA800",
                                            fontSize: "12px",
                                            mt: 0.5,
                                          }}
                                        >
                                          {msg}
                                        </Typography>
                                      )}
                                    />
                                  </Stack>
                                  <Stack direction="column" spacing={1}>
                                    <Box>
                                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                                        {t(
                                          "pages.verification.directors.passport_heading"
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        sx={{ color: "text.secondary", mb: 2 }}
                                      >
                                        {t(
                                          "pages.verification.directors.passport_subheading"
                                        )}
                                      </Typography>
                                    </Box>
                                    <UploadMultiFile
                                      sxWrapper={{ mb: 3 }}
                                      fieldName={`shareholders[${index}].passport`}
                                      disabled={isSubmitting}
                                      acceptedFileTypes={
                                        DEFAULT_ALLOWED_MIME_TYPES
                                      }
                                      filesLimit={20}
                                      isInitialFiles={[]}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                            </>
                          ))}
                        </div>
                      )}
                    </FieldArray>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Box>
                    <IconStatus
                      data={values.proofOfAddress}
                      form_error={false}
                    />
                  </Box>
                  <Box sx={{ width: "37.3%" }}>
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                        {t(
                          "pages.verification.proof_of_address_deployment.heading"
                        )}
                      </Typography>
                      <Box>
                        <Tooltip
                          title={
                            t(
                              "pages.verification.proof_of_address_deployment.help_text"
                            )!
                          }
                          placement={placement()}
                        >
                          <HelpIcon
                            sx={{
                              color: (theme) => theme.palette.grey[400],
                              width: "18px",
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </Stack>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mb: 2 }}
                    >
                      {t(
                        "pages.verification.proof_of_address_deployment.subheading"
                      )}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "57.6%" }}>
                    <UploadMultiFile
                      sxWrapper={{ mb: 3 }}
                      fieldName="proofOfAddress"
                      disabled={isSubmitting}
                      acceptedFileTypes={DEFAULT_ALLOWED_MIME_TYPES}
                      filesLimit={20}
                      isInitialFiles={initialValues.proofOfAddress}
                    />
                  </Box>
                </Stack>
                {status && (
                  <Alert severity="error" variant="outlined" sx={{ mb: 3 }}>
                    {status.msg}
                  </Alert>
                )}
                {isSubmitting && (
                  <Box sx={{ mb: 3 }}>
                    <LinearProgress />
                  </Box>
                )}
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    id="continue-button"
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size="1em" sx={{ color: "#fff" }} />
                      ) : (
                        <></>
                      )
                    }
                    disabled={isSubmitting || !isValid}
                  >
                    {t("global.continue")}
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Card>
    </Container>
  );
};

export default DeploymentSubmitDocuments;
