import { Container, Alert, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { useLedgersQueryLMS } from "../../queries/eradLMSledgers";
import { Box } from "@mui/system";
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Stack,
} from "@mui/material";
import { EradLMSLedger } from "../../types/EradLMSLedger";
import React from "react";
import FinancingOffer from "../Capital/FinancingOffer";
import FinancingOfferDetail from "../Capital/FinancingOfferDetail";
const DeploymentSignContracts = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg" sx={{ py: 5, px: 0 }} disableGutters>
      {/* Sign the contract alert */}
      <Alert
        variant="outlined"
        severity="info"
        sx={{
          backgroundColor: "#fff",
          mb: 2.5,
          color: "#062F41",
          border: "1px solid #05F1D8",
          fontWeight: 500,
          "& .MuiAlert-icon": {
            color: "#05F1D8",
          },
        }}
      >
        {t("pages.deployment.contract_alert_start")}
        <Link
          sx={{ color: "#062F41", textDecoration: "underline" }}
          href="mailto:support@erad.co"
        >
          (support@erad.co)
        </Link>
        {t("pages.deployment.contract_alert_end")}
      </Alert>
      {/* Loan list */}
      <LoanList />
    </Container>
  );
};
const LoanList = () => {
  const { company } = useAuth();
  const { t } = useTranslation();

  const { data: loans, isLoading: isLoadingLedgers } = useLedgersQueryLMS(
    company?.["@id"]!
  );
  return (
    <>
      {isLoadingLedgers && (
        <Box sx={{ mt: 5 }}>
          <LinearProgress />
        </Box>
      )}
      {!isLoadingLedgers && loans && (
        <Box sx={{ mt: 4 }}>
          {loans.map((loan: EradLMSLedger) => {
            if (loan.status === "completed" || loan.status === "cancelled") {
              return <></>;
            }
            return (
              <React.Fragment key={loan.ledgerID}>
                <Typography
                  variant="subtitle2"
                  component="p"
                  sx={{
                    color: "#062F41",
                    mb: 1,
                  }}
                >
                  {t("pages.capital.offer", {
                    number: `#${loan.ledgerID}`,
                  })}
                </Typography>
                <Grid container spacing={3} columns={20} key={loan.ledgerID}>
                  <Grid item xs={6.5}>
                    <FinancingOffer ledger={loan} />
                  </Grid>
                  <Grid item xs={13.5}>
                    <FinancingOfferDetail ledger={loan} />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          })}
        </Box>
      )}
    </>
  );
};
export default DeploymentSignContracts;
