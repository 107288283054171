import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CompanyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2361_28388)">
        <path
          d="M21 23.5V9.72959C21 9.07041 20.6752 8.45356 20.1317 8.08057L13.1317 3.27665C12.4498 2.80868 11.5502 2.80868 10.8683 3.27665L3.86831 8.08057C3.32481 8.45356 3 9.07041 3 9.7296V23.5"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path d="M21 13.5L12 7.5" stroke="currentColor" strokeWidth="2" />
        <path d="M3 13.5L12 7.5" stroke="currentColor" strokeWidth="2" />
        <path d="M21 18.5L12 12.5" stroke="currentColor" strokeWidth="2" />
        <path d="M3 18.5L12 12.5" stroke="currentColor" strokeWidth="2" />
        <path d="M21 23.5L12 17.5" stroke="currentColor" strokeWidth="2" />
        <path d="M3 23.5L12 17.5" stroke="currentColor" strokeWidth="2" />
        <path d="M24 23.5L0 23.5" stroke="currentColor" strokeWidth="2" />
        <path d="M12 23.5L12 2.5" stroke="currentColor" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_2361_28388">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
