const path = (root: string, sublink: string) => {
  return `${root}${sublink}`;
};

export const PATH_AUTH = {
  login: "/login",
  register: "/signup",
  companyExists: "/company-exists",
  companyExistsRequested: "/company-exists/requested",
  verifyEmail: "/verify-email",
  verifyPhone: "/verify-phone",
  tellAboutBusiness: "/tell-about-business",
  verifyResetPasswordEmail: "/verify-reset-password-email",
  resetPassword: "/reset-password",
  setNewPassword: "/set-new-password/:id/:token",
};

export const PATH_PAGE = {
  home: "/",
  page404: "/404",
};

export const PATH_CAPITAL = {
  root: "/capital",
};

const ROOTS_CONNECTOR_REDIRECT_URI = "/connector-redirect-uri";
export const PATH_CONNECTOR_REDIRECT_URI = {
  root: ROOTS_CONNECTOR_REDIRECT_URI,
  native: path(ROOTS_CONNECTOR_REDIRECT_URI, "/native"),
  custom: path(ROOTS_CONNECTOR_REDIRECT_URI, "/custom"),
};

export const PATH_CONNECTOR_CALLBACK = {
  salla: "/callback/salla",
};
export const PATH_INTEGRATION_CALLBACK = {
  direct: "/connect-direct",
};

const ROOTS_ONBOARDING = "/onboarding";
export const PATH_ONBOARDING = {
  root: ROOTS_ONBOARDING,
  connectSystems: path(ROOTS_ONBOARDING, "/connect-systems"),
  connectorLinked: path(ROOTS_ONBOARDING, "/connect-systems/connector-linked"),
  customFivetranConnector: path(
    ROOTS_ONBOARDING,
    "/connect-systems/custom-fivetran-connector"
  ),
  customFivetranConnectorConnectionTest: path(
    ROOTS_ONBOARDING,
    "/connect-systems/custom-fivetran-connector/connection-test"
  ),
  customFivetranConnectorRedirectBack: path(
    ROOTS_ONBOARDING,
    "/connect-systems/custom-fivetran-connector/"
  ),
  verification: path(ROOTS_ONBOARDING, "/verification"),
  additionalDocumentsKSA: path(ROOTS_ONBOARDING, "/additional-documents"),
  applicationOverview: path(ROOTS_ONBOARDING, "/application-overview"),
  underReview: path(ROOTS_ONBOARDING, "/under-review"),
};
const ROOTS_DEPLOYMENT = "/deployment";
export const PATH_DEPLOYMENT = {
  root: ROOTS_DEPLOYMENT,
  acceptOffer: path(ROOTS_DEPLOYMENT, "/accept-offer"),
  submitKyc: path(ROOTS_DEPLOYMENT, "/submit-kyc"),
  signContract: path(ROOTS_DEPLOYMENT, "/sign-contract"),
};

export const PATH_STATEMENT = {
  root: "/statement",
};

const ROOT_PAYMENTS = "/payments";
export const PATH_PAYMENTS = {
  root: ROOT_PAYMENTS,
  charge: path(ROOT_PAYMENTS, "/charge"),
  subscription: path(ROOT_PAYMENTS, "/subscription"),
  transactions: path(ROOT_PAYMENTS, "/transactions"),
  refunds: path(ROOT_PAYMENTS, "/refunds"),
  settlements: path(ROOT_PAYMENTS, "/settlements"),
};

const ROOT_CARDS = "/cards";
export const PATH_ERAD_CARD = {
  root: ROOT_CARDS,
  detail: path(ROOT_CARDS, "/details"),
  transactions: path(ROOT_CARDS, "/transactions"),
};

export const PATH_DASHBOARD = {
  root: "/dashboard",
};

const ROOTS_SETTINGS = "/settings";
export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  account: path(ROOTS_SETTINGS, "/account"),
  company: path(ROOTS_SETTINGS, "/company"),
  dataSources: path(ROOTS_SETTINGS, "/data-sources"),
  team: path(ROOTS_SETTINGS, "/team"),
  dataSourcesAddNewConnector: path(
    ROOTS_SETTINGS,
    "/data-sources/add-new-connector"
  ),
  dataSourcesConnectorLinked: path(
    ROOTS_SETTINGS,
    "/data-sources/add-new-connector/connector-linked"
  ),
  dataSourcesCustomFivetranConnector: path(
    ROOTS_SETTINGS,
    "/data-sources/add-new-connector/custom-fivetran-connector"
  ),
  dataSourcesCustomFivetranConnectorConnectionTest: path(
    ROOTS_SETTINGS,
    "/data-sources/add-new-connector/custom-fivetran-connector/connection-test"
  ),
  dataSourcesCustomFivetranConnectorRedirectBack: path(
    ROOTS_SETTINGS,
    "/data-sources/add-new-connector/custom-fivetran-connector/"
  ),
};

export const PATH_AFTER_LOGIN = PATH_CAPITAL.root;
