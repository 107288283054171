export const filterNullValues = (props: any) => {
  return Object.keys(props)
    .filter(
      (propKey) => props[propKey] !== null && props[propKey] !== undefined
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        [item]: String(props[item]),
      }),
      {}
    );
};
