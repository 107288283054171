import React, { useMemo, useState } from "react";

interface CountryContextValue {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
}

const CountryContext = React.createContext({} as CountryContextValue);
CountryContext.displayName = "CountryConxtext";

const CountryProvider: React.FC = (props) => {
  const [country, setCountry] = useState<string>("");

  const value = useMemo(
    () => ({
      country,
      setCountry,
    }),
    [country, setCountry]
  );

  return <CountryContext.Provider value={value} {...props} />;
};

export { CountryContext, CountryProvider };
