import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TransactionsEradCardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5333 22H4C2.89543 22 2 21.1046 2 20V4C2 2.89543 2.89543 2 4 2H10.7876C11.318 2 11.8267 2.21071 12.2018 2.58579L17.9475 8.33153C18.3226 8.7066 18.5333 9.21531 18.5333 9.74574V20C18.5333 21.1046 17.6379 22 16.5333 22ZM0 4C0 1.79086 1.79086 0 4 0H10.7876C11.8485 0 12.8659 0.421428 13.616 1.17157L19.3618 6.91732C20.1119 7.66746 20.5333 8.68488 20.5333 9.74574V20C20.5333 22.2091 18.7425 24 16.5333 24H4C1.79086 24 0 22.2091 0 20V4ZM11.25 5H5C4.44772 5 4 5.44772 4 6V6.5H12.25V6C12.25 5.44772 11.8023 5 11.25 5ZM4 10V7.5H12.25V8V10C12.25 10.5523 11.8023 11 11.25 11H5C4.44772 11 4 10.5523 4 10ZM16 15H4V13H16V15ZM4 19H11V17H4V19Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
