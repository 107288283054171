import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  FeeIcon,
  MoneyIcon,
  RateIcon,
  OwedIcon,
  TenureIcon,
  AdminIcon,
} from "../Icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Price from "../Currency/CurrencyPrice";
import { EradLMSLedger } from "../../types/EradLMSLedger";
type FinancingOfferProps = {
  ledger: EradLMSLedger;
};

const FinancingOffer = ({ ledger }: FinancingOfferProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      key={ledger.ledgerID}
      sx={{
        mb: 4,
      }}
    >
      <CardContent sx={{ pt: 4, pl: 5, "&:last-child": { pb: 4 } }}>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <MoneyIcon fill="none" sx={{ fill: "none" }} />
            <Typography variant="h3" component="div" sx={{ mb: 4 }}>
              <Price
                price={Number(ledger.loanAmount)}
                currency={ledger.currency}
              />
            </Typography>
          </Stack>
          {/* New fee added here */}
          <Stack
            direction="row"
            alignItems="center"
            color="text.secondary"
            spacing={1.5}
          >
            <AdminIcon fill="none" sx={{ fill: "none" }} />
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2" component="p">
                {t("global.admin_fee_title")}
              </Typography>
              <Typography variant="body2" component="p">
                <Price
                  price={
                    (Number(ledger.loanAmount) * Number(ledger.adminFee || 0)) /
                    100
                  }
                  currency={ledger.currency}
                />
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            color="text.secondary"
            spacing={1.5}
          >
            <FeeIcon fill="none" sx={{ fill: "none" }} />
            <Typography variant="body2" component="p">
              {t("global.fee", {
                fee: ledger.fee?.toFixed(2),
              })}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            color="text.secondary"
            spacing={1.5}
          >
            <OwedIcon fill="none" sx={{ fill: "none" }} />
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2" component="p">
                {t("global.payable")}
              </Typography>
              <Typography variant="body2" component="div">
                <Price
                  price={ledger.dueLoanAmount}
                  currency={ledger.currency}
                />
              </Typography>
            </Stack>
          </Stack>
          {"fixedInstallmentAmount" in ledger ? (
            <Stack
              direction="row"
              alignItems="center"
              color="text.secondary"
              spacing={1.5}
            >
              <TenureIcon fill="none" sx={{ fill: "none" }} />
              <Typography variant="body2" component="p">
                {t("global.months", {
                  months: ledger.tenure,
                })}
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              color="text.secondary"
              spacing={1.5}
            >
              <RateIcon fill="none" sx={{ fill: "none" }} />
              <Typography variant="body2" component="p">
                {t("global.monthly_sales", {
                  remittanceRate: ledger.revenuePercentage,
                })}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
      {(ledger.minAmount || ledger.maxAmount) && (
        <>
          <CardActions
            disableSpacing
            sx={{
              backgroundColor: "#F5F5F5",
              justifyContent: "center",
              pt: 1.5,
              px: 4,
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              color="text.secondary"
              sx={{ cursor: "default" }}
            >
              <Typography variant="body2" component="p">
                {t("installments.minimum_maximum.title")}
              </Typography>
              <IconButton onClick={() => handleExpandClick()}>
                {expanded ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </Stack>
          </CardActions>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            sx={{ backgroundColor: "#F5F5F5" }}
          >
            <CardContent sx={{ px: 5, pt: 0.5, "&:last-child": { pb: 2.5 } }}>
              <Stack
                direction="row"
                sx={{ margin: "auto" }}
                color="text.secondary"
                justifyContent="space-evenly"
              >
                <Box>
                  <Typography variant="subtitle2" color="text.disabled">
                    {t("installments.minimum_maximum.minimum")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="text.secondary"
                  >
                    {ledger.minAmount && (
                      <Price
                        price={Number(ledger.minAmount)}
                        currency={ledger.currency}
                      />
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" color="text.disabled">
                    {t("installments.minimum_maximum.maximum")}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color="text.secondary"
                  >
                    {ledger.maxAmount && (
                      <Price
                        price={Number(ledger.maxAmount)}
                        currency={ledger.currency}
                      />
                    )}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
};

export default FinancingOffer;
