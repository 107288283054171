import { Logout as LogoutIcon } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import { HEADER } from "../../../../config/config";
import useAuth from "../../../../hooks/useAuth";
import useIsMountedRef from "../../../../hooks/useIsMountedRef";
import { PATH_SETTINGS } from "../../../Routes/paths";
import { isLngAR } from "../../../../utils/i18n";
import Logo from "../../../Logo";
import LogoAR from "../../../Logo/LogoAr";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import i18n, {
  languagesOptions,
  SupportedLanguagesEnum,
} from "../../../../utils/i18n";
import Menu from "../Navbar/Menu";
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
}));

type LanguageSwitcherType = {
  value?: SupportedLanguagesEnum;
  onChange?: (lngId: SupportedLanguagesEnum) => void;
};

const MobileHeader: React.FC<LanguageSwitcherType> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const isMountedRef = useIsMountedRef();
  const [selectLng, setSelectLng] = useState(i18n.language);

  const [alignment, setAlignment] = useState(selectLng);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    if (isMountedRef.current) {
      handleClose();
    }
  };
  const changeLanguageHandler = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: SupportedLanguagesEnum
  ) => {
    if (onChange) {
      onChange(newLanguage);
    } else {
      // just for preventing uncontrolled state warning
      setSelectLng(newLanguage);
      i18n.changeLanguage(newLanguage);
      window.location.reload();
    }
  };
  return (
    <RootStyle color="transparent" elevation={0} position="relative">
      <Toolbar
        sx={{
          minHeight: "100% !important",
          justifyContent: "space-between",
          pt: 2,
        }}
      >
        <Stack direction="row" alignItems="center">
          {isLngAR() ? (
            <LogoAR sxWidth={40} sxHeight={23} showText={true} />
          ) : (
            <Logo sxWidth={40} sxHeight={23} showText={true} />
          )}
        </Stack>
        <Button onClick={handleOpen}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box
              sx={{
                width: 42,
                height: 42,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1.5,
              }}
            >
              <MenuIcon
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              />
            </Box>
          </Stack>
        </Button>
        <Popover
          id="account-appbar"
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          elevation={1}
          sx={{ backgroundColor: "rgba(6, 47, 65, 0.75)" }}
        >
          <List sx={{ p: "20px", width: 200 }}>
            <Menu />
            <ListItemButton
              component={RouterLink}
              to={PATH_SETTINGS.root}
              onClick={handleClose}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={t("menu.account.settings")}
                primaryTypographyProps={{ variant: "subtitle2" }}
              />
            </ListItemButton>
            <Box sx={{ my: 4 }}>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                onChange={changeLanguageHandler}
                exclusive
                aria-label="Language Toggler"
                sx={{
                  width: "100%",
                  display: "flex",
                  ".MuiToggleButtonGroup-grouped": { flexGrow: 1 },
                }}
              >
                {languagesOptions.map(({ id, label }) => (
                  <ToggleButton
                    value={id}
                    sx={{ width: "100%", justifyContent: "center" }}
                  >
                    {label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <ListItemButton
              sx={{ py: 1, px: 2, backgroundColor: "text.secondary" }}
              onClick={handleLogout}
            >
              <ListItemIcon>
                <LogoutIcon sx={{ color: "#fff" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" sx={{ color: "#fff", fontSize: 14 }}>
                    {t("global.logout")}
                  </Typography>
                }
              />
            </ListItemButton>
          </List>
        </Popover>
      </Toolbar>
    </RootStyle>
  );
};

export default MobileHeader;
