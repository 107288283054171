import { Close, Check } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  SxProps,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PrivateFile } from "../../types/PrivateFile";
import { convertBytesToMbsOrKbs } from "../../utils/file";
import { getActivePath } from "../../utils/route";
import { PATH_SETTINGS } from "../Routes/paths";
import { useUpdateFilePasswordMutation } from "../../queries/file";
import { useTranslation } from "react-i18next";
import { SwitchCustom as Switch } from "../../theme/overrides/Switch";

export interface SingleFileProps {
  file: PrivateFile;
  onRemove: (file: PrivateFile) => void;
  removeable: boolean;
  sx?: SxProps<Theme>;
}

const SingleBankFile = ({
  file,
  onRemove,
  removeable,
  sx = [],
}: SingleFileProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [passwordEnabled, setPasswordEnabled] = useState(
    file.mimeType === "application/pdf"
  );
  const [password, setPassword] = useState(file.password || "");

  const { mutate: updateFilePassword } = useUpdateFilePasswordMutation();

  const handleSavePassword = () => {
    updateFilePassword({ file, password }, {});
  };

  useEffect(() => {
    if (passwordEnabled && password !== file.password) {
      handleSavePassword();
    }
  }, [password, passwordEnabled]);

  useEffect(() => {
    if (password === "" && passwordEnabled) {
      localStorage.setItem(`password-empty${file["@id"]}`, "true");
    } else {
      localStorage.removeItem(`password-empty${file["@id"]}`);
    }
    window.dispatchEvent(new Event("localStorageUpdated"));
  }, [password, passwordEnabled]);

  const handleRemove = () => {
    localStorage.removeItem(`password-empty${file["@id"]}`);
    window.dispatchEvent(new Event("localStorageUpdated"));
    onRemove(file);
  };

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        {/* File name and size */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexGrow: 1 }}
        >
          <Box>
            <Typography variant="subtitle2" color="text.primary">
              {file.originalFilename}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {convertBytesToMbsOrKbs(file.size!)}
            </Typography>
          </Box>

          {/* Password toggle and input */}
          {file.mimeType === "application/pdf" && (
            <Stack direction="row" alignItems="center" spacing={20}>
              <Stack direction="column" alignItems="center" spacing={1}>
                <Typography variant="caption" color="text.secondary">
                  {t("connectors.csv_connector.statement_password")}
                </Typography>
                <Switch
                  checked={passwordEnabled}
                  onChange={(e) => setPasswordEnabled(e.target.checked)}
                  sx={{ transform: "scale(0.9)" }}
                />
              </Stack>
              <Box sx={{ width: "250px" }}>
                {passwordEnabled && (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <TextField
                      variant="standard"
                      defaultValue="Normal"
                      label={t(
                        "connectors.csv_connector.enter_statement_password"
                      )}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      sx={{
                        minWidth: "200px",
                        "& .MuiInputLabel-root": { color: "text.secondary" },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </Stack>
                )}
              </Box>
            </Stack>
          )}
        </Stack>

        {/* Remove button */}
        {removeable &&
          !getActivePath(
            PATH_SETTINGS.dataSourcesAddNewConnector,
            location.pathname
          ) && (
            <IconButton
              size="small"
              onClick={() => {
                handleRemove();
              }}
            >
              <Close sx={{ color: "text.secondary" }} />
            </IconButton>
          )}
      </Stack>
    </Box>
  );
};

export default SingleBankFile;
