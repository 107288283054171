import { useQuery, UseQueryResult } from "react-query";
import { fetch } from "../utils/dataAccess";
import { EradLMSLedger } from "../types/EradLMSLedger";

export const getLedgersLMS = async (
  companyIRI: string
): Promise<EradLMSLedger[]> => {
  let { data } = await fetch({ url: `${companyIRI}/lms-ledgers` }, false);
  return data["hydra:member"];
};

export const useLedgersQueryLMS = (
  companyIRI: string
): UseQueryResult<EradLMSLedger[], Error> => {
  return useQuery(["getLedgersLMS", companyIRI], () =>
    getLedgersLMS(companyIRI)
  );
};
