import { Outlet } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import useAuth from "../hooks/useAuth";

const CallbackGuard = ({ children }: { children?: JSX.Element }) => {
  const { isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return children || <Outlet />;
};

export default CallbackGuard;
