import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DataIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2361_28400)">
        <circle cx="12" cy="4.5" r="3" stroke="currentColor" strokeWidth="2" />
        <path d="M12 20.5L12 7.5" stroke="currentColor" strokeWidth="2" />
        <path d="M12 24.5V22.5" stroke="currentColor" strokeWidth="2" />
        <path d="M8 24.5V22.5" stroke="currentColor" strokeWidth="2" />
        <path d="M16 24.5V22.5" stroke="currentColor" strokeWidth="2" />
        <path
          d="M8 20.5L8 20.1085C8 19.4189 7.64476 18.778 7.06 18.4125L4.94 17.0875C4.35524 16.722 4 16.0811 4 15.3915L4 14.5"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M16 20.5L16 20.1085C16 19.4189 16.3552 18.778 16.94 18.4125L19.06 17.0875C19.6448 16.722 20 16.0811 20 15.3915L20 14.5"
          stroke="currentColor"
          strokeWidth="2"
        />
        <circle cx="4" cy="11.5" r="3" stroke="currentColor" strokeWidth="2" />
        <circle cx="20" cy="11.5" r="3" stroke="currentColor" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_2361_28400">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
