import i18n, {
  languagesOptions,
  SupportedLanguagesEnum,
} from "../../utils/i18n";
import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Stack,
  Theme,
  Typography,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Link,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { WhatsAppIcon } from "../Icons";
import { useTranslation } from "react-i18next";
import { SUPPORT_WHATSAPP } from "../../config/config";
import { Logout as LogoutIcon } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// This language switcher used for login/signup steps on mobile

type LanguageSwitcherType = {
  value?: SupportedLanguagesEnum;
  onChange?: (lngId: SupportedLanguagesEnum) => void;
};

const LanguageSwitcherForMobile: React.FC<LanguageSwitcherType> = ({
  value,
  onChange,
}) => {
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const [selectLng, setSelectLng] = useState(value || i18n.language);
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const [alignment, setAlignment] = React.useState(selectLng);
  const isMountedRef = useIsMountedRef();

  const handleLogout = async () => {
    await logout();
    localStorage.clear();
    if (isMountedRef.current) {
      handleClose();
    }
  };
  const changeLanguageHandler = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: SupportedLanguagesEnum
  ) => {
    if (onChange) {
      onChange(newLanguage);
    } else {
      // just for preventing uncontrolled state warning
      setSelectLng(newLanguage);
      i18n.changeLanguage(newLanguage);
      window.location.reload();
    }
  };
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Stack>
        {/* info button dropdown */}
        <Button onClick={handleOpen}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box
              sx={{
                backgroundColor: "#fff",
                width: 42,
                height: 42,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1.5,
              }}
            >
              <InfoIcon
                sx={{
                  color: (theme) => theme.palette.grey[700],
                }}
              />
            </Box>
          </Stack>
        </Button>
        <Popover
          id="account-appbar"
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          elevation={1}
        >
          <List sx={{ p: "20px", width: 200 }}>
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                onChange={changeLanguageHandler}
                exclusive
                aria-label="Language Toggler"
                sx={{
                  width: "100%",
                  display: "flex",
                  ".MuiToggleButtonGroup-grouped": { flexGrow: 1 },
                }}
              >
                {languagesOptions.map(({ id, label }) => (
                  <ToggleButton
                    value={id}
                    sx={{ width: "100%", justifyContent: "center" }}
                  >
                    {label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            {/* WhatsApp Contact Us section */}
            {/* <ListItemButton sx={{ p: 0 }}>
              <ListItemIcon>
                <WhatsAppIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="h6"
                    sx={{ color: "text.primary", fontSize: 16 }}
                  >
                    {t("global.contact_us")}
                  </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton sx={{ p: 0, mt: "12px" }}>
              <ListItemText
                primary={
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {t("global.contact_us_long_message.start")}
                    <Link href={SUPPORT_WHATSAPP} target="_blank">
                      {t("global.contact_us_long_message.contact")}
                    </Link>
                    {t("global.contact_us_long_message.end")}
                  </Typography>
                }
              />
            </ListItemButton> */}
            {user ? (
              <ListItemButton
                sx={{ py: 1, px: 2, backgroundColor: "text.secondary", mt: 4 }}
                onClick={handleLogout}
              >
                <ListItemIcon>
                  <LogoutIcon sx={{ color: "#fff" }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      sx={{ color: "#fff", fontSize: 16 }}
                    >
                      {t("global.logout")}
                    </Typography>
                  }
                />
              </ListItemButton>
            ) : null}
          </List>
        </Popover>
      </Stack>
    </>
  );
};

export default LanguageSwitcherForMobile;
