import { useMutation, UseMutationResult } from "react-query";
import { Company } from "../types/Company";
import { KycKybDocumentsValues } from "../types/FormValues";
import { fetch } from "../utils/dataAccess";
import ViolationError from "../utils/violationError";

interface KycKybPostProps {
  companyIri: string;
  values: KycKybDocumentsValues;
}

export const kycKybPost = async ({
  companyIri,
  values,
}: KycKybPostProps): Promise<Company> => {
  const { data } = await fetch({
    url: `${companyIri}/kyc-kyb-documents`,
    method: "PATCH",
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
    data: {
      kycKybDocuments: values,
    },
  });
  return data;
};

export const useKycKybPost = (): UseMutationResult<
  Company,
  Error | ViolationError,
  KycKybPostProps
> => {
  return useMutation(kycKybPost);
};

interface KycKybStatusProps {
  companyIri: string;
  status: string;
}

export const kycKybStatus = async ({
  companyIri,
  status,
}: KycKybStatusProps): Promise<Company> => {
  const { data } = await fetch({
    url: `${companyIri}/kyc-kyb-documents-status-change`,
    method: "PUT",
    data: {
      kycKybStatus: status,
    },
  });
  return data;
};

export const useKycKybStatus = (): UseMutationResult<
  Company,
  Error | ViolationError,
  KycKybStatusProps
> => {
  return useMutation(kycKybStatus);
};
