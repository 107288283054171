import i18n, {
  languagesOptions,
  SupportedLanguagesEnum,
} from "../../utils/i18n";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import React, { useState } from "react";

type LanguageSwitcherType = {
  value?: SupportedLanguagesEnum;
  onChange?: (lngId: SupportedLanguagesEnum) => void;
};

const LanguageSwitcher: React.FC<LanguageSwitcherType> = ({
  value,
  onChange,
}) => {
  const [selectLng, setSelectLng] = useState(value || i18n.language);
  const changeLanguageHandler =
    onChange ||
    (async (lng: SupportedLanguagesEnum) => {
      // just for preventing uncontrolled state warning
      setSelectLng(lng);
      i18n.changeLanguage(lng);
      window.location.reload();
    });

  const StyledMenuItem = styled(MenuItem)(
    ` 
      &:hover, &.Mui-focusVisible {
        background-color: rgba(238, 245, 247, 1)
      }`
  );

  return (
    <>
      <FormControl variant="standard" sx={{ minWidth: 10, p: 0.5 }}>
        <Select defaultValue={i18n.language} value={selectLng} disableUnderline>
          {languagesOptions.map(({ id, label }) => (
            <StyledMenuItem
              value={id}
              disabled={id === selectLng}
              onClick={() =>
                changeLanguageHandler(
                  selectLng === SupportedLanguagesEnum.EN
                    ? SupportedLanguagesEnum.AR
                    : SupportedLanguagesEnum.EN
                )
              }
              sx={{ color: "rgba(0, 0, 0, 0.6)" }}
              key={id}
            >
              {label}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
export default LanguageSwitcher;
