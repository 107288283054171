import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RateIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.30518 6.34876C5.30518 5.24419 6.20061 4.34875 7.30518 4.34875H14.0408C14.5712 4.34875 15.0799 4.55947 15.455 4.93454L16.782 6.26156L18.109 7.58857C18.4841 7.96364 18.6948 8.47235 18.6948 9.00278V17.6512C18.6948 18.7557 17.7994 19.6512 16.6948 19.6512H7.30517C6.2006 19.6512 5.30518 18.7557 5.30518 17.6512V6.34876Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="9.5252"
        cy="9.76782"
        r="1.25"
        transform="rotate(135 9.5252 9.76782)"
        fill="currentColor"
      />
      <circle
        cx="14.1214"
        cy="14.364"
        r="1.25"
        transform="rotate(135 14.1214 14.364)"
        fill="currentColor"
      />
      <path
        d="M14.8284 9.06067L9.17151 14.7175"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
