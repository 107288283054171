import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ChargeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15 4.83333V4.83333C15 3.91286 14.2538 3.16667 13.3333 3.16667H12M9 8.16667V8.16667C9 9.08714 9.74619 9.83333 10.6667 9.83333H12M12 3.16667H10.6667C9.74619 3.16667 9 3.91286 9 4.83333V4.83333C9 5.75381 9.74619 6.5 10.6667 6.5H13.3333C14.2538 6.5 15 7.24619 15 8.16667V8.16667C15 9.08714 14.2538 9.83333 13.3333 9.83333H12M12 3.16667V1M12 9.83333V12"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18.5 5L19 5C20.1046 5 21 5.89543 21 7L21 21C21 22.1046 20.1046 23 19 23L5 23C3.89543 23 3 22.1046 3 21L3 7C3 5.89543 3.89543 5 5 5L5.5 5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M6 15H18" stroke="currentColor" strokeWidth="2" />
      <path d="M6 19H12" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
}
