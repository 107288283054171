import { IStripePriceItem, IStripeProductItem } from "../queries/eradPay";

//token_type
export enum ApiTokenTypeEnum {
  TAP_DIRECT = "tap_direct",
  TAP_CUSTOM = "tap_custom",
  STRIPE_DIRECT = "stripe_direct",
  BNPL = "buy_now_pay_later",
}
//service_type
export enum ApiTokenServiceTypeEnum {
  UAE = "UAE",
  KSA = "KSA",
}
//payment_type
export enum PaymentTypeEnum {
  CHARGE = "Charge",
  SUBSCRIPTION = "Subscription",
  CUSTOM = "Custom",
}
//transaction_status
export enum TransactionStatusEnum {
  SUCCESSFUL = "Successful",
  AUTHORIZED = "Authorized",
  CAPTURED = "Captured",
  VOIDED = "Voided",
  PENDING = "Pending",
  REFUNDED = "Refunded",
  FAILED = "Failed",
  UNKNOWN = "Unknown",
  ALL = "Show All",
}
//payment_list_time_range
export enum PaymentsListTimeRangeEnum {
  LAST_HOUR = "Last hour",
  LAST_24H = "Last 24h",
  LAST_3D = "Last 3 days",
  LAST_7D = "Last 7 days",
  LAST_15D = "Last 15 days",
  LAST_30D = "Last 30 days",
}

export const apiTokenPaymentTypeMap = {
  [PaymentTypeEnum.CHARGE]: ApiTokenTypeEnum.TAP_DIRECT,
  [PaymentTypeEnum.SUBSCRIPTION]: ApiTokenTypeEnum.TAP_DIRECT,
  [PaymentTypeEnum.CUSTOM]: ApiTokenTypeEnum.TAP_CUSTOM,
};

//refund_reason
export enum RefundReasonEnum {
  DUPLICATE = "Duplicate",
  FRAUDULENT = "Fraudulent",
  REQUESTED_BY_CUSTOMER = "Requested by customer",
}

//auth_capture_mode
export enum EradPayAuthCaptureMode {
  VOID = "VOID",
  CAPTURE = "CAPTURE",
}
//pay_mode
export enum EradPayModeEnum {
  SANDBOX = "SANDBOX",
  LIVE = "LIVE",
}
//pay_cursor_direction
export enum EradPayCursorDirectionEnum {
  DIRECT = "direct",
  REVERSE = "reverse",
}

export enum EradPayStripePriceTypeEnum {
  ONE_TIME = "one_time",
  RECURRING = "recurring",
}

export interface IPaymentCharge {
  amount: string;
  currencyCode: string;
  paymentId: string;
  apiKey: ApiToken;
  fullname: string;
  email: string;
  webhookUrl: string;
  mode: EradPayModeEnum;
}

export interface IApiTokenProps {
  apiToken: ApiToken;
}

export interface ITrxStatusOption {
  id: keyof typeof TransactionStatusEnum | string;
  value: string;
  label: string;
}

export interface IAuthCaptureValues {
  amount: string;
}

export interface IRefundValues extends IAuthCaptureValues {
  reason: ITrxStatusOption;
}

export interface IPaymentCharge {
  amount: string;
  currencyCode: string;
  productName?: string;
  stripeProduct?: IStripeProductItem;
  stripePrice?: IStripePriceItem;
  stripePromoCode?: boolean;
  paymentId: string;
  apiKey: ApiToken;
  fullname: string;
  email: string;
  phoneCode: string;
  phoneCodeDial: string;
  phoneNumber: string;
  webhookUrl: string;
  redirectUrl: string;
  isSaveCard: boolean;
  customerId: string;
  mode: EradPayModeEnum;
  isAuthSettingsEnabled: boolean;
  isAuthorizeOnly: boolean;
  authAutoCaptureMode: EradPayAuthCaptureMode;
  authTime: string;
}

export interface IPaymentCustomCharge {
  fullname: string;
  email: string;
  phoneCode: string;
  phoneCodeDial: string;
  phoneNumber: string;
  tapPaymentFormIsValid: boolean;
  mode: EradPayModeEnum;
}

//subscription_frequency
export enum SubscriptionFrequencyEnum {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  BIMONTHLY = "Bimonthly",
  QUARTERLY = "Quarterly",
  HALF_YEARLY = "Half-yearly",
  YEARLY = "Yearly",
}

export interface IPaymentSubscription {
  amount: string;
  amountFirstTime: string;
  currencyCode: string;
  frequency: ITrxStatusOption;
  startDate: string;
  numberOfPayments: string;
  fullname: string;
  email: string;
  phoneCode: string;
  phoneCodeDial: string;
  phoneNumber: string;
  productName: string;
  stripeProduct?: IStripeProductItem;
  stripePrice?: IStripePriceItem;
  stripePromoCode?: boolean;
  paymentId: string;
  apiKey: ApiToken;
  webhookUrl: string;
  redirectUrl: string;
  mode: EradPayModeEnum;
}

export class ApiToken {
  public "@id"?: string;

  constructor(
    _id?: string,
    public id?: string,
    public token?: string,
    public type?: ApiTokenTypeEnum,
    public serviceType?: ApiTokenServiceTypeEnum,
    public label?: string,
    public allowRefund?: boolean,
    public allowStripePayment?: boolean,
    public payBgColor?: string,
    public payButtonBgColor?: string,
    public payButtonTextColor?: string,
    public logo?: string,
    public logoUrl?: string,
    public currency?: string
  ) {
    this["@id"] = _id;
  }
}
