import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CopyLinkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9976 5.50712L15.3666 3.13806C16.884 1.62065 19.3442 1.62065 20.8617 3.13806C22.3791 4.65548 22.3791 7.1157 20.8617 8.63312L16.1236 13.3712C14.6061 14.8887 12.1459 14.8886 10.6285 13.3712L9.21428 14.7854C11.5127 17.0839 15.2393 17.0839 17.5378 14.7854L22.2759 10.0473C24.5743 7.74887 24.5743 4.02232 22.2759 1.72385C19.9774 -0.574615 16.2509 -0.574618 13.9524 1.72385L11.5833 4.09291L12.9976 5.50712ZM11.0022 18.4926L8.63311 20.8617C7.1157 22.3791 4.65548 22.3791 3.13806 20.8617C1.62065 19.3442 1.62065 16.884 3.13806 15.3666L7.87618 10.6285C9.39359 9.11107 11.8538 9.11108 13.3712 10.6285L14.7854 9.21428C12.487 6.91582 8.76043 6.91581 6.46196 9.21428L1.72385 13.9524C-0.574617 16.2509 -0.574616 19.9774 1.72385 22.2759C4.02231 24.5743 7.74886 24.5743 10.0473 22.2759L12.4164 19.9068L11.0022 18.4926Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
