import {
  Button,
  CircularProgress,
  FormHelperText,
  Stack,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { VerifyBusinessPurposeValues } from "../../types/VerifyBusinessInfoValues";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  useUpdateFundPurposePost,
  useUpdateDesiredAmountPost,
} from "../../queries/businessInfo";
import ViolationError from "../../utils/violationError";
import { getRevenueOptionDetails } from "./VerifyBusinessInfoForm";
import { PATH_ONBOARDING } from "../Routes/paths";
import Slider from "@mui/material/Slider";
import { ThemeProvider, createTheme } from "@mui/material";
import { isLngAR } from "../../utils/i18n";
const SHOW_ERROR_TIMEOUT_MS = 5000;

export const FundPurposes = [
  {
    id: 1,
    label: "pages.fund_purpose.marketing",
    value: "marketing",
  },
  {
    id: 2,
    label: "pages.fund_purpose.inventory_purchasing",
    value: "inventory_purchasing",
  },
  {
    id: 3,
    label: "pages.fund_purpose.improving_cashflow",
    value: "improving_cashflow",
  },
  {
    id: 4,
    label: "pages.fund_purpose.research_and_development",
    value: "research_and_development",
  },
  {
    id: 5,
    label: "pages.fund_purpose.company_expansion",
    value: "company_expansion",
  },
  {
    id: 6,
    label: "pages.fund_purpose.other",
    value: "other",
  },
];

export type ChooseFundPurposeExtendedType = {
  currentPage: number;
  handleChangePage: (new_page: number) => void;
};

const ChooseFundPurposeForm = ({
  handleChangePage,
  currentPage,
}: ChooseFundPurposeExtendedType) => {
  const { t } = useTranslation(["translation", "countries"]);
  const formikRef = useRef<FormikProps<VerifyBusinessPurposeValues>>(null);
  const { company, refetchCompany, setCompany } = useAuth();
  const [purposes, setPurposes] = useState<string[]>(
    company?.fundingPurposes ?? []
  );

  const { mutate: updateFundPurpose } = useUpdateFundPurposePost();
  const { mutate: updateDesiredAmount } = useUpdateDesiredAmountPost();

  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(null), SHOW_ERROR_TIMEOUT_MS);
    }
  }, [error]);

  const possibleFinancing = getRevenueOptionDetails(
    company?.monthlyRevenue ?? 0
  )["possibleFinancing"];

  const min = 30000;
  const max = possibleFinancing * 1000;
  const [value, setValue] = useState(possibleFinancing ? (min + max) / 2 : 0);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    if (event.target.checked) {
      setPurposes((prevItems) => [...prevItems, value]);
    } else {
      setPurposes((prevItems) => prevItems.filter((item) => item !== value));
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };
  const handleChangeCommitted = (
    event: React.SyntheticEvent | Event,
    newValue: number | number[]
  ) => {
    updateDesiredAmount(
      {
        financingAmount: (newValue as number).toLocaleString(),
      },
      {
        onError: (error) => {
          if (error instanceof ViolationError) {
            setError(`${error.defaultErrorMsg}`);
          } else {
            setError(`${error.message}`);
          }
        },
        onSuccess: (data) => {
          if (data.success) {
            if (company && company.companyDetails) {
              company.companyDetails.financingAmount = (
                newValue as number
              ).toLocaleString();
            }
            refetchCompany();
          } else {
            setError(t("error.unexpected"));
          }
        },
      }
    );
  };

  const AmountSlider = createTheme({
    direction: isLngAR() ? "rtl" : "ltr",
    components: {
      MuiSlider: {
        styleOverrides: {
          valueLabel: {
            top: 60,
            backgroundColor: "white",
            color: "#062F41",
            border: "1px solid #062F41",
            borderRadius: "5px",
            padding: "10 20px",
            fontSize: "14px",
            "&:before": {
              display: "none",
            },
          },
          thumb: {
            border: "none",
            backgroundColor: "#05F1D8",
          },
          track: {
            border: "none",
            backgroundColor: "#05F1D8",
          },
          rail: {
            backgroundColor: "#E0E0E0",
          },
        },
      },
    },
  });

  return (
    <>
      <Stack
        direction={"column"}
        sx={{
          width: "100%",
          backgroundColor: "#2E7D320A",
          border: "1px solid #00B272",
          p: 4,
          borderRadius: "12px",
          mb: 4,
        }}
        spacing={2.5}
        alignItems={"center"}
      >
        <Typography color="#062F41" variant="h3" component="h1">
          {t("pages.fund_purpose.great_news")}
        </Typography>
        <Typography color="#00B272" variant="h5" component="h1">
          {t("pages.fund_purpose.eligible_message")}
        </Typography>
        <Typography color="#058658" variant="h2" component="h1">
          {company?.countryCode === "SA" ? "SAR" : "AED"}{" "}
          {possibleFinancing?.toLocaleString()}
          ,000.00*
        </Typography>
      </Stack>

      <div>
        <Typography color="text.secondary">
          {t("pages.fund_purpose.fund_request")}
        </Typography>
        <Typography color="text.secondary">
          {t("pages.fund_purpose.fund_eligible")}
        </Typography>

        <Box
          sx={{
            width: 850,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 3,
            mb: 8,
          }}
        >
          <Typography
            color="text.secondary"
            sx={{ minWidth: 70, fontSize: "16px", fontWeight: 500 }}
          >
            {min.toLocaleString()}
          </Typography>
          <ThemeProvider theme={AmountSlider}>
            <Slider
              value={value}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommitted}
              min={min}
              max={max}
              step={10000}
              valueLabelDisplay="on"
              valueLabelFormat={(value) =>
                (company?.countryCode === "SA" ? "SAR " : "AED ") +
                value.toLocaleString()
              }
              sx={{ mx: 2 }}
            />
          </ThemeProvider>
          <Typography
            color="text.secondary"
            sx={{ minWidth: 80, fontSize: "16px", fontWeight: 500 }}
          >
            {max.toLocaleString()}
          </Typography>
        </Box>
      </div>

      <Formik
        innerRef={formikRef}
        validateOnMount
        initialValues={
          {
            purposes: [],
          } as VerifyBusinessPurposeValues
        }
        onSubmit={({}, { setSubmitting, resetForm }) => {
          if (purposes.length === 0) {
            purposes.push("other");
          }
          updateFundPurpose(
            {
              purposes: purposes,
            },
            {
              onError: (error) => {
                if (error instanceof ViolationError) {
                  setError(`${error.defaultErrorMsg}`);
                } else {
                  setError(`${error.message}`);
                }
              },
              onSuccess: (data) => {
                if (data.success) {
                  window.location.href = PATH_ONBOARDING.root;
                } else {
                  setError(t("error.unexpected"));
                }
              },
            }
          );
        }}
      >
        {({
          isValid,
          isSubmitting,
          touched,
          errors,
          values,
          setSubmitting,
        }) => (
          <Form>
            {error && (
              <Box sx={{ my: 3 }}>
                <FormHelperText error sx={{ textAlign: "left" }}>
                  {error}
                </FormHelperText>
              </Box>
            )}
            <Stack direction={"column"} spacing={1}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {t("pages.fund_purpose.fund_question")}
              </Typography>
              <FormGroup>
                <Grid container mb={4}>
                  {FundPurposes.map((ps) => {
                    const label = t(ps.label);

                    return (
                      <Grid item xs={6} key={ps.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                color: "gray",
                                "&.Mui-checked": { color: "gray" },
                              }}
                              checked={purposes.includes(ps.value)}
                              onChange={handleCheckBoxChange}
                              name={ps.value}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormGroup>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={4}
            >
              <Button
                id="back-button"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleChangePage(currentPage - 1)}
              >
                {t("global.back")}
              </Button>
              <Button
                id="register-button"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size="1.2rem" sx={{ color: "#fff" }} />
                  ) : (
                    <></>
                  )
                }
              >
                {t("global.continue")}
              </Button>
            </Stack>
            <Stack>
              <Typography variant="caption">
                {t("pages.fund_purpose.fund_agreement")}
              </Typography>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChooseFundPurposeForm;
