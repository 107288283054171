import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUploadPrivateFileMutation } from "../../queries/file";
import { PrivateFile } from "../../types/PrivateFile";
import { convertBytesToMbsOrKbs } from "../../utils/file";
import ViolationError from "../../utils/violationError";
import SingleFileNameStyle from "./SingleFileNameStyle";

export interface SingleFileUploadWithProgressProps {
  file: File;
  onError: (file: File, error: string) => void;
  onRemove: (file: File) => void;
  onUpload: (file: File, uploadedFile: PrivateFile) => void;
  sx?: SxProps<Theme>;
}

export function SingleBankFileUploadWithProgress({
  file,
  onError,
  onRemove,
  onUpload,
  sx = [],
}: SingleFileUploadWithProgressProps) {
  const [progress, setProgress] = useState(0);
  const { mutate: uploadFile, isLoading } = useUploadPrivateFileMutation();

  useEffect(() => {
    async function upload() {
      await uploadFile(
        {
          file,
          requestConfig: {
            onUploadProgress: function (progressEvent: ProgressEvent) {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          },
        },
        {
          onError: (error) => {
            if (error instanceof ViolationError) {
              onError(file, error.defaultErrorMsg);
            } else {
              onError(file, error.message);
            }
          },
          onSuccess: (uploadedFile) => {
            onUpload(file, uploadedFile);
          },
        }
      );
    }

    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        {/* File name and size */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={10}
          sx={{ flexGrow: 1 }}
        >
          <Box>
            <SingleFileNameStyle variant="subtitle2" color="text.secondary">
              {file.name}
            </SingleFileNameStyle>
            <Typography variant="body2" color="text.secondary">
              {convertBytesToMbsOrKbs(file.size!)}
            </Typography>
          </Box>
        </Stack>

        {/* Remove button */}
        <IconButton size="small" onClick={() => onRemove(file)}>
          <Close sx={{ color: "text.secondary" }} />
        </IconButton>
      </Stack>

      {progress < 100 ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : isLoading ? (
        <LinearProgress />
      ) : null}
    </Box>
  );
}
