import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RefundIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_5629_29756)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25797 8.66992C4.61265 5.00251 8.14043 2.38965 12.276 2.38965C17.5837 2.38965 21.8864 6.69235 21.8864 12C21.8864 17.3077 17.5837 21.6104 12.276 21.6104C8.01103 21.6104 4.39249 18.8314 3.13725 14.9828L1.23582 15.603C2.75154 20.2502 7.12007 23.6104 12.276 23.6104C18.6882 23.6104 23.8864 18.4122 23.8864 12C23.8864 5.58779 18.6882 0.389648 12.276 0.389648C7.24867 0.389648 2.96995 3.58427 1.35453 8.05175L0 7.61185L1.05476 12.5706L4.8218 9.17779L3.25797 8.66992Z"
          fill="black"
          fillOpacity="0.38"
        />
        <path
          d="M15.1719 10.3333V10.3333C15.1719 9.41286 14.4257 8.66667 13.5052 8.66667H12.1719M9.17188 13.6667V13.6667C9.17188 14.5871 9.91807 15.3333 10.8385 15.3333H12.1719M12.1719 8.66667H10.8385C9.91807 8.66667 9.17188 9.41286 9.17188 10.3333V10.3333C9.17188 11.2538 9.91807 12 10.8385 12H13.5052C14.4257 12 15.1719 12.7462 15.1719 13.6667V13.6667C15.1719 14.5871 14.4257 15.3333 13.5052 15.3333H12.1719M12.1719 8.66667V6.5M12.1719 15.3333V17.5"
          stroke="black"
          strokeOpacity="0.38"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_5629_29756">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
