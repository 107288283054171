import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function OwedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_4329_59882)">
        <path
          d="M3 22.4684C3 22.9098 3.53017 23.1347 3.84752 22.8279L6.45292 20.3093C6.6681 20.1013 7.01658 20.1276 7.19804 20.3656L9.03097 22.7694C9.23108 23.0318 9.62606 23.0318 9.82617 22.7694L11.6024 20.4399C11.8025 20.1775 12.1975 20.1775 12.3976 20.4399L14.1738 22.7694C14.3739 23.0318 14.7689 23.0318 14.969 22.7694L16.802 20.3656C16.9834 20.1276 17.3319 20.1013 17.5471 20.3093L20.1525 22.8279C20.4698 23.1347 21 22.9098 21 22.4684V4.00008C21 2.89551 20.1046 2.00008 19 2.00008H5C3.89543 2.00008 3 2.89551 3 4.00008V22.4684Z"
          stroke="currentColor"
          strokeWidth="2"
        />
        <path
          d="M15.7155 8.95663V8.95663C15.7155 7.81661 14.7913 6.89244 13.6513 6.89244H12M8.28442 13.085V13.085C8.28442 14.225 9.2086 15.1492 10.3486 15.1492H12M12 6.89244H10.3486C9.2086 6.89244 8.28442 7.81661 8.28442 8.95663V8.95663C8.28442 10.0967 9.20859 11.0208 10.3486 11.0208H13.6513C14.7913 11.0208 15.7155 11.945 15.7155 13.085V13.085C15.7155 14.225 14.7913 15.1492 13.6513 15.1492H12M12 6.89244V4.20898M12 15.1492V17.8327"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_4329_59882">
          <rect
            width="24"
            height="24"
            transform="translate(24 0.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
