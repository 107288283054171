import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { PrivateFile } from "../../types/PrivateFile";
import { convertBytesToMbsOrKbs } from "../../utils/file";
import { getActivePath } from "../../utils/route";
import { PATH_SETTINGS } from "../Routes/paths";
import SingleFileNameStyle from "./SingleFileNameStyle";

export interface SingleFileProps {
  file: PrivateFile;
  onRemove: (file: PrivateFile) => void;
  removeable: boolean;
  sx?: SxProps<Theme>;
}

const SingleFile = ({
  file,
  onRemove,
  removeable,
  sx = [],
}: SingleFileProps) => {
  const location = useLocation();
  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Stack direction="row" alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <Box>
            <SingleFileNameStyle variant="subtitle2" color="text.secondary">
              {file.originalFilename}
            </SingleFileNameStyle>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="text.secondary">
              {convertBytesToMbsOrKbs(file.size!)}
            </Typography>
          </Box>
        </Stack>

        {removeable &&
          !getActivePath(
            PATH_SETTINGS.dataSourcesAddNewConnector,
            location.pathname
          ) && (
            <Box sx={{ ml: 2 }}>
              <IconButton size="small" onClick={() => onRemove(file)}>
                <Close sx={{ color: "text.secondary" }} />
              </IconButton>
            </Box>
          )}
      </Stack>
    </Box>
  );
};

export default SingleFile;
