import {
  QueryObserverResult,
  RefetchOptions,
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_AFTER_LOGIN, PATH_AUTH } from "../components/Routes/paths";
import { EditPasswordValues } from "../types/EditPasswordValues";
import { LoginCredentials } from "../types/LoginCredentials";
import {
  BuyNowPayLaterRegisterValues,
  RegisterValues,
} from "../types/RegisterValues";
import { ResetPasswordValues } from "../types/ResetPasswordValues";
import { SetNewPasswordValues } from "../types/SetNewPasswordValues";
import { Token } from "../types/Token";
import { User } from "../types/User";
import { fetch } from "../utils/dataAccess";
import { setSession } from "../utils/jwt";
import ViolationError from "../utils/violationError";
import ContextError from "../utils/contextError";
import { EditUserLanguageValues } from "../EditUserLanguageValues";
export const login = async (values: LoginCredentials): Promise<Token> => {
  const { data } = await fetch({
    url: "/token/authentication",
    method: "POST",
    data: values,
  });
  return data;
};

type LoginLocationState = {
  from?: {
    pathname?: string;
  };
};

export const useLogin = (
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<User, Error>>
): UseMutationResult<Token, Error | ViolationError, LoginCredentials> => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LoginLocationState;
  const from = state?.from?.pathname || PATH_AFTER_LOGIN;

  return useMutation(login, {
    onSuccess: (data) => {
      // Start user session.
      setSession(data.token);
      window.localStorage.setItem("app-login", Date.now().toString());
      // Refetch current user.
      refetch();
      navigate(from, { replace: true });
    },
  });
};

export const logout = async () => {
  const { data } = await fetch({ url: "/token/revoke", method: "POST" });

  return data;
};

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(logout, {
    onSettled: () => {
      setSession(null);
      queryClient.clear();
      window.localStorage.setItem("app-logout", Date.now().toString());
      navigate(PATH_AUTH.login, { replace: true });
    },
  });
};

export const register = async (
  values: RegisterValues | BuyNowPayLaterRegisterValues
): Promise<User> => {
  const { data } = await fetch({ url: "/users", method: "POST", data: values });
  return data;
};

export const useRegister = (): UseMutationResult<
  User,
  Error | ViolationError | ContextError,
  RegisterValues | BuyNowPayLaterRegisterValues
> => {
  return useMutation(register);
};

export const confirmRegistration = async ({
  id,
  values,
}: {
  id: string;
  values: { token: string };
}): Promise<User> => {
  const { data } = await fetch({
    url: `/users/${id}/email-verify`,
    method: "PUT",
    data: values,
  });
  return data;
};

export const useConfirmRegistration = (): UseMutationResult<
  User,
  Error | ViolationError,
  { id: string; values: { token: string } }
> => {
  return useMutation(confirmRegistration);
};

export const resetPassword = async (
  values: ResetPasswordValues
): Promise<User | null> => {
  const { data } = await fetch({
    url: `users/reset-password`,
    method: "POST",
    data: values,
  });

  return data;
};

export const useResetPassword = (): UseMutationResult<
  User | null,
  Error | ViolationError,
  ResetPasswordValues
> => {
  return useMutation(resetPassword);
};

export const resetPasswordCheck = async ({
  id,
  values,
}: {
  id: string;
  values: { token: string };
}): Promise<User> => {
  const { data } = await fetch({
    url: `/users/${id}/check/reset-password`,
    method: "PUT",
    data: values,
  });
  return data;
};

export const useResetPasswordCheck = (): UseMutationResult<
  User,
  Error | ViolationError,
  { id: string; values: { token: string } }
> => {
  return useMutation(resetPasswordCheck);
};

interface UserToken {
  token: string;
  userId: string;
}
export const setNewPassword = async (
  values: SetNewPasswordValues & UserToken
): Promise<User> => {
  const { data } = await fetch({
    url: `/users/${values.userId}/set-new-password`,
    method: "PUT",
    data: values,
  });

  return data;
};

export const useNewPassword = (): UseMutationResult<
  User,
  Error | ViolationError,
  SetNewPasswordValues & UserToken
> => {
  return useMutation(setNewPassword);
};

interface UserId {
  userId: string;
}
export const editPassword = async (
  values: EditPasswordValues & UserId
): Promise<User> => {
  const { data } = await fetch({
    url: `/users/${values.userId}/edit-password`,
    method: "PUT",
    data: values,
  });

  return data;
};
export const editUserLanguage = async (
  values: EditUserLanguageValues & UserId
): Promise<User> => {
  const { data } = await fetch({
    url: `/users/${values.userId}/edit-language`,
    method: "PUT",
    data: values,
  });

  return data;
};

export const useEditPassword = (): UseMutationResult<
  User,
  Error | ViolationError,
  EditPasswordValues & UserId
> => {
  return useMutation(editPassword);
};

export const useEditUserLanguage = (): UseMutationResult<
  User,
  Error | ViolationError,
  EditUserLanguageValues & UserId
> => {
  return useMutation(editUserLanguage);
};
