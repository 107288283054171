import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PaymentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.06407 8.58531C6.80509 5.84429 11.2492 5.84429 13.9902 8.58531C14.3367 8.93183 14.6388 9.30475 14.897 9.69722L16.5679 8.59813C16.2355 8.09283 15.8475 7.61423 15.4044 7.17109C11.8823 3.64902 6.17193 3.64902 2.64985 7.17109C-0.872217 10.6932 -0.872217 16.4036 2.64985 19.9256C6.17193 23.4477 11.8823 23.4477 15.4044 19.9256C15.5665 19.7635 15.7213 19.5966 15.8687 19.4253L14.3528 18.1207C14.2379 18.2542 14.117 18.3846 13.9902 18.5114C11.2492 21.2525 6.80509 21.2525 4.06407 18.5114C1.32304 15.7704 1.32304 11.3263 4.06407 8.58531ZM7.60435 9.07117H8.02931V7.75561H10.0293V9.07117H10.4543C11.9903 9.07117 13.2355 10.3164 13.2355 11.8524H11.2355C11.2355 11.4209 10.8857 11.0712 10.4543 11.0712H9.02931H7.60435C7.17291 11.0712 6.82315 11.4209 6.82315 11.8524C6.82315 12.2838 7.17291 12.6336 7.60435 12.6336H10.4543C11.9903 12.6336 13.2355 13.8788 13.2355 15.4148C13.2355 16.9508 11.9903 18.196 10.4543 18.196H10.0293V19.5115H8.02931V18.196H7.60435C6.06834 18.196 4.82315 16.9508 4.82315 15.4148H6.82315C6.82315 15.8462 7.17291 16.196 7.60435 16.196H9.02931H10.4543C10.8857 16.196 11.2355 15.8462 11.2355 15.4148C11.2355 14.9833 10.8857 14.6336 10.4543 14.6336H7.60435C6.06834 14.6336 4.82315 13.3884 4.82315 11.8524C4.82315 10.3164 6.06834 9.07117 7.60435 9.07117ZM23.6198 10.7497L20.9481 8.07805L19.8875 9.13871L22.1323 11.3836H14.9762V12.8836H22.7359C23.8495 12.8836 24.4072 11.5372 23.6198 10.7497ZM17.9711 19.1891L15.2994 16.5175C14.512 15.73 15.0697 14.3836 16.1833 14.3836H19.9597H23.9431V15.8836H19.9597H16.7869L19.0318 18.1285L17.9711 19.1891Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
