import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FandBIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1372_7839)">
          <rect
            width="24"
            height="24"
            transform="translate(0.5)"
            fill="white"
          />
          <rect
            x="2.5"
            y="9"
            width="20"
            height="3"
            rx="1"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M20.5 9C20.5 5.13401 16.9183 2 12.5 2C8.08172 2 4.5 5.13401 4.5 9"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M13.5 2C13.5 1.44772 13.0523 1 12.5 1C11.9477 1 11.5 1.44772 11.5 2"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M6.91489 17.1532L5.85084 18.2867C5.59904 18.555 5.546 18.9538 5.71894 19.2785L6.89999 21.4962C7.18744 22.0359 7.74911 22.3732 8.36063 22.3732H13.1947C14.8545 22.3732 16.4265 21.6276 17.4767 20.3422L20.2317 16.9702C20.7863 16.2913 20.6448 15.2839 19.9247 14.7841C19.2852 14.3402 18.4127 14.458 17.9137 15.0555L15.6461 17.7708C15.1201 18.4008 14.3416 18.7649 13.5209 18.7649H10.7669C10.7587 18.7649 10.7521 18.7583 10.7521 18.7502C10.7521 18.742 10.7587 18.7354 10.7669 18.7354H13.3039C14.139 18.7354 14.816 18.0584 14.816 17.2233C14.816 16.3881 14.139 15.7111 13.3039 15.7111H10.2465C8.98448 15.7111 7.77864 16.2331 6.91489 17.1532Z"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M5.0033 18.0006L7.5033 23.0006L2.00337 23.0006L2.00343 18.0005L5.0033 18.0006Z"
            stroke="#062F41"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1372_7839">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
