export const SITE_ENV = process.env.REACT_APP_SITE_ENV;

export const APP_NAME = "Erad";

export const NAVBAR = {
  DASHBOARD_WIDTH: 190,
};

export const HEADER = {
  MOBILE_HEIGHT: 55,
  DESKTOP_HEIGHT: 55,
};

export const GTM_ID = process.env.REACT_APP_GTM_ID;
export const INSTRUCTION_VIDEO_EN = process.env.REACT_APP_INSTRUCTION_VIDEO_EN;
export const INSTRUCTION_VIDEO_AR = process.env.REACT_APP_INSTRUCTION_VIDEO_AR;
export const MUTUAL_NON_DISCLOSURE_AGREEMENT_EN =
  process.env.REACT_APP_MUTUAL_NON_DISCLOSURE_AGREEMENT_EN;
export const MUTUAL_NON_DISCLOSURE_AGREEMENT_AR =
  process.env.REACT_APP_MUTUAL_NON_DISCLOSURE_AGREEMENT_AR;
export const RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_TRACES_SAMPLE_RATE =
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;
export const STRIPE_PUB_KEY_SANDBOX =
  process.env.REACT_APP_STRIPE_PUB_KEY_SANDBOX;
export const STRIPE_PUB_KEY_LIVE = process.env.REACT_APP_STRIPE_PUB_KEY_LIVE;
export const TAP_PAYMENTS_UAE_API_KEY_SANDBOX =
  process.env.REACT_APP_TAP_PAYMENTS_UAE_API_KEY_SANDBOX;
export const TAP_PAYMENTS_UAE_API_KEY_LIVE =
  process.env.REACT_APP_TAP_PAYMENTS_UAE_API_KEY_LIVE;
export const TAP_PAYMENTS_KSA_API_KEY_SANDBOX =
  process.env.REACT_APP_TAP_PAYMENTS_KSA_API_KEY_SANDBOX;
export const TAP_PAYMENTS_KSA_API_KEY_LIVE =
  process.env.REACT_APP_TAP_PAYMENTS_KSA_API_KEY_LIVE;

export const SUPPORT_MAIL = "support@erad.co";
export const SUPPORT_WHATSAPP = "https://wa.me/966115102955";

export const HTML_ALLOWED_TAGS_LIST = [
  "a",
  "br",
  "strong",
  "i",
  "p",
  "em",
  "u",
  "code",
  "s",
  "ul",
  "li",
  "ol",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];
export const AR_HTML_ALLOWED_TAGS_LIST = [
  "a",
  "br",
  "strong",
  "i",
  "p",
  "em",
  "u",
  "s",
  "ul",
  "li",
  "ol",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

export const DEFAULT_ALLOWED_MIME_TYPES = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
};

export const DEFAULT_CERTIFICATION_MIME_TYPES = {
  "application/pdf": [".pdf"],
};

export const DEFAULT_CONSENT_MIME_TYPES = {
  "application/pdf": [".pdf"],
};

export const LANGUAGE_SWITCHER_ENABLED =
  process.env.REACT_APP_LANGUAGE_SWITCHER_ENABLED === "true";

export const HOW_IT_WORKS_ENABLED =
  process.env.REACT_APP_HOW_IT_WORKS_ENABLED === "true";

export const ERAD_CARDS_ENABLED =
  process.env.REACT_APP_ERAD_CARDS_ENABLED === "true";
