import libphonenumber from "google-libphonenumber";
import * as Yup from "yup";
import { PrivateFile } from "../types/PrivateFile";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, "alphanumeric", function (msg) {
  return this.test({
    name: "alphanumeric",
    exclusive: true,
    message: msg,
    test: (value): boolean => {
      if (!value) {
        return false;
      }
      try {
        return /^[a-z0-9]+$/i.test(value);
      } catch (e) {
        return false;
      }
    },
  });
});

// Add phone validation for Yup.
Yup.addMethod(Yup.string, "phone", function (msg) {
  return this.test({
    name: "phone",
    exclusive: true,
    message: msg,
    test: (value, testContext): boolean => {
      if (!value) {
        return true;
      }
      try {
        if (testContext.parent.phoneCode) {
          return phoneUtil.isValidNumberForRegion(
            phoneUtil.parse(value, testContext.parent.phoneCode),
            testContext.parent.phoneCode
          );
        } else {
          return phoneUtil.isValidNumber(phoneUtil.parse(value));
        }
      } catch (e) {
        return false;
      }
    },
  });
});

// Add mime validation for Yup.
Yup.addMethod(
  Yup.mixed,
  "mime",
  function (allowedMimeTypes: string[], msg: string) {
    return this.test({
      name: "mime",
      exclusive: true,
      message: msg,
      test: (value: PrivateFile | PrivateFile[] | undefined): boolean => {
        if (value === undefined) {
          return false;
        }
        if (Array.isArray(value)) {
          return value.every(
            (item) => item.mimeType && allowedMimeTypes.includes(item.mimeType)
          );
        } else {
          return !!value.mimeType && allowedMimeTypes.includes(value.mimeType);
        }
      },
    });
  }
);
