import { Stack, Theme, Typography, Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import Logo from "../Logo";
import { PATH_PAGE } from "../Routes/paths";
import { getExactPath } from "../../utils/route";
import LogoAR from "../Logo/LogoAr";
import { isLngAR } from "../../utils/i18n";
import LanguageSwitcher from "../LanguageSwitcher";
import LanguageSwitcherForMobile from "../LanguageSwitcherForMobile";
import { LANGUAGE_SWITCHER_ENABLED } from "../../config/config";
import { useTranslation } from "react-i18next";
import useCountry from "../../hooks/useCountry";
import useAuth from "../../hooks/useAuth";
import { useIsMobile } from "../../hooks/useIsMobile";

export default function LogoOnlyLayout() {
  const location = useLocation();
  const isMobile = useIsMobile();

  const { t } = useTranslation();
  const { company } = useAuth();
  const { country } = useCountry();

  return (
    <Stack direction="column" justifyContent="space-between" height="100vh">
      <Stack
        direction="row-reverse"
        sx={(theme) => ({
          position: "absolute",
          right: {
            xs: "1em",
            sm: theme.spacing(8),
          },
          top: {
            xs: theme.spacing(6),
            sm: theme.spacing(8),
          },
        })}
      >
        {LANGUAGE_SWITCHER_ENABLED &&
          (isMobile ? <LanguageSwitcherForMobile /> : <LanguageSwitcher />)}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        sx={(theme) => ({
          position: "absolute",
          left: {
            xs: "1em",
            sm: theme.spacing(8),
          },
          top: theme.spacing(8),
        })}
      >
        {isLngAR() ? (
          <LogoAR
            sxWidth={40}
            sxHeight={isMobile ? 23 : 63}
            showText={isMobile ? true : false}
          />
        ) : (
          <Logo
            sxWidth={40}
            sxHeight={isMobile ? 23 : 63}
            showText={isMobile ? true : false}
          />
        )}
      </Stack>

      <Outlet />

      <Stack
        direction="row"
        alignItems="center"
        sx={{ pr: { xs: 2, sm: 5 }, pb: 5, pl: { xs: 2, sm: 8 } }}
      >
        <Box
          sx={{ height: "46px", pt: 0.9, display: { xs: "none", sm: "block" } }}
        >
          {isLngAR() ? (
            <LogoAR
              showIcon={false}
              showText={!getExactPath(PATH_PAGE.page404, location.pathname)}
            />
          ) : (
            <Logo
              showIcon={false}
              showText={!getExactPath(PATH_PAGE.page404, location.pathname)}
            />
          )}
        </Box>
        {(company?.countryCode === "AE" || country === "AE") && (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              paddingRight: { xs: "0px", sm: "12em" },
              paddingLeft: { xs: "0px", sm: "2em" },
              width: "100%",
              mt: { xs: "120px", sm: "auto" },
            }}
          >
            <Typography
              sx={{
                color: "#80949E",
                fontSize: "14px",
              }}
            >
              {t("global.disclosure")}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
