import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MoneyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_1503_15205)">
        <path
          d="M15.7155 10.4358V10.4358C15.7155 9.29573 14.7913 8.37156 13.6513 8.37156H12M8.28442 14.5641V14.5641C8.28442 15.7042 9.2086 16.6283 10.3486 16.6283H12M12 8.37156H10.3486C9.2086 8.37156 8.28442 9.29573 8.28442 10.4358V10.4358C8.28442 11.5758 9.20859 12.4999 10.3486 12.4999H13.6513C14.7913 12.4999 15.7155 13.4241 15.7155 14.5641V14.5641C15.7155 15.7042 14.7913 16.6283 13.6513 16.6283H12M12 8.37156V5.68811M12 16.6283V19.3118"
          stroke="currentColor"
          strokeWidth="2"
        />
        <circle
          cx="12"
          cy="12.5"
          r="10.9544"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1503_15205">
          <rect
            width="24"
            height="24"
            transform="translate(24 0.5) rotate(90)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
