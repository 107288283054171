import { Navigate, Outlet } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { PATH_AFTER_LOGIN } from "../components/Routes/paths";
import useAuth from "../hooks/useAuth";

const GuestGuard = ({ children }: { children?: JSX.Element }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  return children || <Outlet />;
};

export default GuestGuard;
