import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { fetch } from "../utils/dataAccess";
import ViolationError from "../utils/violationError";
import {
  ApiToken,
  ApiTokenTypeEnum,
  EradPayAuthCaptureMode,
  EradPayModeEnum,
  EradPayStripePriceTypeEnum,
  PaymentTypeEnum,
  RefundReasonEnum,
  SubscriptionFrequencyEnum,
} from "../types/Payment";
import { SettlementReport } from "../types/SettlementReport";
import Collection from "../types/Collection";

interface IGeneratePaymentQueryPostProps {
  [key: string]: any;
}

interface IGenerateStripePaymentLinkProps {
  token: string;
  mode: EradPayModeEnum;
  stripePriceId: IStripePriceItem["id"];
  paymentId: string;
  paymentType: PaymentTypeEnum;
  promoCodeAllow?: boolean;
}

interface IApiTokensValidatePostProps {
  token: string;
  type: ApiTokenTypeEnum;
}

interface ITransactionsListQueryParams {
  mode: EradPayModeEnum;
  token: string;
  status?: string;
  timeRange?: string;
  datePickerRange?: { from: string; to: string };
}

interface IRefundPaymentPostProps {
  token: string;
  mode: EradPayModeEnum;
  trx_id: string;
  amount: string;
  currency: string;
  reason: RefundReasonEnum;
  payment_id: string;
  fee_amount: string;
  external_source: string;
}

interface ISettlementReportsListQueryParams {
  mode: EradPayModeEnum;
  token: ApiToken;
  page: number;
  limit: number;
}

interface IAuthVoidPostProps {
  token: string;
  mode: EradPayModeEnum;
  auth_id: string;
  payment_id: string;
}

interface IAuthCapturePostProps extends IAuthVoidPostProps {
  amount: string;
}

interface ICheckTapTransactionQueryParams {
  paymentId: string;
  authorize: string;
  token: string;
  mode: EradPayModeEnum;
  source?: string;
}

interface ICreateChargePostProps {
  mode: EradPayModeEnum;
  token: string;
  charge_token: string;
  amount: number;
  currency: string;
  fullname: string;
  email: string;
  phone: {
    code: string;
    number: string;
  };
  payment_id: string;
  webhook_url: string;
  is_save_card: boolean;
  customer_id: string;
  redirect_url: string;
  customer_redirect_url: string;
  auth_auto_capture_mode?: EradPayAuthCaptureMode;
  auth_time?: number;
  is_authorize_only?: boolean;
  metadata?: {
    type?: PaymentTypeEnum;
    amount?: number;
    frequency?: SubscriptionFrequencyEnum;
    start_date?: string;
    payments_number?: number;
    platform?: string;
  };
}

export interface ITransactionItem {
  number: number;
  trxId: string;
  trxDate: string;
  trxStatus: string;
  trxUrl?: string;
  currency: string;
  amount: string;
  fees: number;
  vat: number;
  net: number;
  metadata?: {
    merchant_id: string;
    mode: EradPayModeEnum;
    available_to_refund?: number;
    fee_amount?: number;
    can_refund?: boolean;
    can_auth_capture?: boolean;
    can_auth_void?: boolean;
    customer_id?: string;
    customer_redirect_url?: string;
    source: string;
    payer_info?: {
      first_name: string;
      email: string;
      phone: {
        county_code: string;
        number: string;
      };
    };
    payment_platform?: string;
  };
  response?: {
    code: string;
    message: string;
  };
  externalSource: string;
}

export interface IStripeProductItem {
  id: string;
  name: string;
  label: string;
  description: string;
  active: boolean;
  type: string | null;
  live: boolean;
  url: string | null;
  created: number;
  updated: number;
  createdDate: string;
  updatedDate: string;
  metadata: { [key: string]: any };
  raw: { [key: string]: any };
}

export interface IStripePriceItem {
  id: string;
  label: string;
  product_id: string;
  amount: number;
  currency: string;
  type: string;
  recurring?: null | {
    [key: string]: any;
  };
  active: boolean;
  live: boolean;
  billing_scheme: string;
  created: number;
  createdDate: string;
  metadata: { [key: string]: any };
  raw: { [key: string]: any };
}

export interface IRefundItem {
  number: string;
  amount: number;
  currency: string;
  rfdDate: string;
  rfdId: string;
  rfdStatus: string;
  trxId: string;
  customer?: {
    first_name: string;
    email: string;
    phone: {
      country_code: string;
      number: string;
    };
  };
}

export interface IListItem<T> {
  labels: keyof T;
  items: T[];
  page?: number;
  limit?: number;
  total?: number;
  has_more?: boolean;
  cursorNext?: string | null;
  cursorPrev?: string | null;
}

interface IStripePaymentIntentPostParams {
  token: string;
  mode: EradPayModeEnum;
  amount: number;
  currency: string;
  payment_method?: string;
  payment_id?: string;
  payer_info: {
    name?: string;
    email?: string;
    phone?: {
      code?: string;
      number?: string;
    };
  };
}

interface IStripeMinChargeAmount {
  currency: string;
}

interface IStripeMinChargeAmountItem {
  currency: string;
  min_amount: number;
}

interface IStripeProductPricesQueryParams {
  token: ApiToken;
  mode: EradPayModeEnum;
  stripeProduct: IStripeProductItem;
  stripePriceType: EradPayStripePriceTypeEnum;
}

export const getRefunds = async ({
  mode,
  token,
  datePickerRange,
}: ITransactionsListQueryParams): Promise<IListItem<IRefundItem>> => {
  const queryParams = new URLSearchParams({
    mode: mode.toString(),
    token,
    dateFrom: datePickerRange?.from || "",
    dateTo: datePickerRange?.to || "",
  });
  const { data } = await fetch({
    url: `/eradpay/refunds?${queryParams.toString()}`,
  });
  return data;
};

export const getSettlementReports = async ({
  mode,
  token,
  page,
  limit,
}: ISettlementReportsListQueryParams): Promise<
  Collection<SettlementReport>
> => {
  const queryParams = new URLSearchParams({
    mode: mode.toString(),
    page: page.toString(),
    itemsPerPage: limit.toString(),
    "order[createdAt]": "DESC",
    "apiToken.id": token["@id"]!,
  });
  const { data } = await fetch({
    url: `/settlement_reports?${queryParams.toString()}`,
  });
  return data;
};

export const getTransactions = async ({
  mode,
  token,
  status,
  // @TODO remove it later
  timeRange,
  datePickerRange,
}: ITransactionsListQueryParams): Promise<IListItem<ITransactionItem>> => {
  const queryParams = new URLSearchParams({
    mode: mode.toString(),
    token,
    status: status || "",
    // timeRange: timeRange || "",
    dateFrom: datePickerRange?.from || "",
    dateTo: datePickerRange?.to || "",
  });
  const { data } = await fetch({
    url: `/eradpay/transactions?${queryParams.toString()}`,
  });
  return data;
};

export const getApiTokens = async (): Promise<ApiToken[]> => {
  const { data } = await fetch({ url: "/api_tokens" });
  return ((data && data["hydra:member"]) || []).map((item: ApiToken) => ({
    "@id": item["@id"],
    id: item.token,
    label: item.label ? `${item.label} - ${item.token}` : item.token,
    type: item.type,
    allowRefund: item.allowRefund,
  }));
};

export const generatePaymentQuery = async ({
  params,
}: IGeneratePaymentQueryPostProps) => {
  const { data } = await fetch({
    url: "/eradpay/generate-payment-query",
    method: "POST",
    data: params,
  });

  return data;
};

export const generateStripePaymentLink = async (
  postData: IGenerateStripePaymentLinkProps
) => {
  const { data } = await fetch({
    url: "/eradpay/generate-stripe-payment-link",
    method: "POST",
    data: postData,
  });

  return data;
};

export const parsePaymentQuery = async ({
  query,
}: {
  query: string | null;
}) => {
  const { data } = await fetch({
    url: "/eradpay/parse-payment-query",
    method: "POST",
    data: { query },
  });

  return data;
};

export const validateApiToken = async ({
  token,
  type,
}: IApiTokensValidatePostProps) => {
  const { data } = await fetch({
    url: "/eradpay/validate-token",
    method: "POST",
    data: { token, type },
  });

  return data;
};

export const refundPayment = async (data: IRefundPaymentPostProps) => {
  const { data: responseData } = await fetch({
    url: "/eradpay/refunds",
    method: "POST",
    data,
  });

  return responseData;
};

export const authorizeCapture = async (data: IAuthCapturePostProps) => {
  const { data: responseData } = await fetch({
    url: "/eradpay/authorize/capture",
    method: "POST",
    data,
  });

  return responseData;
};

export const authorizeVoid = async (data: IAuthVoidPostProps) => {
  const { data: responseData } = await fetch({
    url: "/eradpay/authorize/void",
    method: "POST",
    data,
  });

  return responseData;
};

export const createStripePaymentIntent = async (
  data: IStripePaymentIntentPostParams
) => {
  const { data: responseData } = await fetch({
    url: "/eradpay/payment-intent",
    method: "POST",
    data: { ...data, payment_method: "card" },
  });

  return responseData;
};

export const createTapCharge = async (data: any) => {
  const { data: responseData } = await fetch({
    url: "/eradpay/payment",
    method: "POST",
    data,
  });

  return responseData;
};

export const checkPayment = async ({
  paymentId,
  authorize,
  token,
  mode,
  source,
}: ICheckTapTransactionQueryParams) => {
  const { data: responseData } = await fetch({
    url: `/eradpay/check-payment?payment_id=${paymentId}&authorize=${authorize}&token=${token}&mode=${mode}&source=${source}`,
  });
  return responseData;
};

export const getStripeProducts = async ({
  token,
  mode,
}: Pick<IStripeProductPricesQueryParams, "token" | "mode">): Promise<
  IStripeProductItem[]
> => {
  const queryParams = new URLSearchParams({
    token: token.id || "",
    mode,
  });
  const { data: responseData } = await fetch({
    url: `/eradpay/get-stripe-products?${queryParams.toString()}`,
  });
  return responseData?.products || [];
};

export const getStripePrices = async ({
  token,
  mode,
  stripeProduct,
  stripePriceType,
}: IStripeProductPricesQueryParams): Promise<IStripePriceItem[]> => {
  const queryParams = new URLSearchParams({
    token: token.id || "",
    mode,
    stripeProductId: stripeProduct.id,
    stripePriceType,
  });
  const { data: responseData } = await fetch({
    url: `/eradpay/get-stripe-product-prices?${queryParams.toString()}`,
  });
  return responseData?.product_prices || [];
};

export const useApiTokensQuery = (): UseQueryResult<ApiToken[], Error> => {
  return useQuery("getApiTokens", () => getApiTokens());
};

export const useGeneratePaymentQueryPost = (): UseMutationResult<
  { query: string | null },
  Error | ViolationError,
  IGeneratePaymentQueryPostProps
> => {
  return useMutation(generatePaymentQuery);
};

export const useGenerateStripePaymentLinkPost = (): UseMutationResult<
  { payment_link: string | null },
  Error | ViolationError,
  IGenerateStripePaymentLinkProps
> => {
  return useMutation(generateStripePaymentLink);
};

export const useParsePaymentQueryPost = (): UseMutationResult<
  IGeneratePaymentQueryPostProps,
  Error | ViolationError,
  { query: string | null }
> => {
  return useMutation(parsePaymentQuery);
};

export const useApiTokensValidatePost = (): UseMutationResult<
  ApiToken | null,
  Error | ViolationError,
  IApiTokensValidatePostProps
> => {
  return useMutation(validateApiToken);
};

export const useRefundPayment = (): UseMutationResult<
  IRefundItem,
  Error | ViolationError,
  IRefundPaymentPostProps
> => {
  return useMutation(refundPayment);
};

export const useAuthorizeCapture = (): UseMutationResult<
  IRefundItem,
  Error | ViolationError,
  IAuthCapturePostProps
> => {
  return useMutation(authorizeCapture);
};

export const useAuthorizeVoid = (): UseMutationResult<
  IRefundItem,
  Error | ViolationError,
  IAuthVoidPostProps
> => {
  return useMutation(authorizeVoid);
};

export const useTransactionsQuery = ({
  mode,
  token,
  status,
  timeRange,
  datePickerRange,
}: ITransactionsListQueryParams): UseQueryResult<
  IListItem<ITransactionItem>,
  Error
> => {
  const queryKey = `getTransactions_${mode}_${status}_${timeRange}_${token}`;
  return useQuery(
    [queryKey, mode, status, timeRange, datePickerRange, token],
    () =>
      getTransactions({
        mode,
        token,
        status,
        timeRange,
        datePickerRange,
      })
  );
};

export const useRefundsQuery = ({
  mode,
  token,
  datePickerRange,
}: ITransactionsListQueryParams): UseQueryResult<
  IListItem<IRefundItem>,
  Error
> => {
  const queryKey = `getRefunds_${mode}_${datePickerRange}`;
  return useQuery([queryKey, mode, token, datePickerRange], () =>
    getRefunds({ mode, token, datePickerRange })
  );
};

export const useSettlementReportsQuery = ({
  mode,
  token,
  page,
  limit,
}: ISettlementReportsListQueryParams): UseQueryResult<
  Collection<SettlementReport>,
  Error
> => {
  const queryKey = `getSettlementReports_${mode}_${token?.id}_${page}_${limit}`;
  return useQuery([queryKey, mode, token?.id, page, limit], () =>
    getSettlementReports({ mode, token, page, limit })
  );
};

export const useTapPaymentPost = (): UseMutationResult<
  ITransactionItem | null,
  Error | ViolationError,
  ICreateChargePostProps
> => {
  return useMutation(createTapCharge);
};

export const useCheckPaymentQuery = ({
  paymentId,
  authorize,
  token,
  mode,
  source,
}: ICheckTapTransactionQueryParams): UseQueryResult<
  ITransactionItem,
  Error
> => {
  const queryKey = `checkPayment_${paymentId}_${authorize}_${token}_${mode}`;
  return useQuery([queryKey, paymentId, authorize], () =>
    checkPayment({ paymentId, authorize, token, mode, source })
  );
};

export const useStripePaymentIntentPost = (): UseMutationResult<
  { client_secret: string },
  Error | ViolationError,
  IStripePaymentIntentPostParams
> => {
  return useMutation(createStripePaymentIntent);
};

export const getStripeMinChargeAmount = async ({
  currency,
}: IStripeMinChargeAmount): Promise<IStripeMinChargeAmountItem> => {
  const { data } = await fetch({
    url: `/eradpay/payment-stripe-min-charge-amount?currency=${currency}`,
  });
  return data;
};

export const useGetStripeMinChargeAmountQuery = ({
  currency,
}: IStripeMinChargeAmount): UseQueryResult<
  IStripeMinChargeAmountItem,
  Error
> => {
  const queryKey = `getStripeMinChargeAmount_${currency}`;
  return useQuery([queryKey, currency], () =>
    getStripeMinChargeAmount({ currency })
  );
};

export const useGetStripeProductsQuery = ({
  token,
  mode,
}: Pick<IStripeProductPricesQueryParams, "token" | "mode">): UseQueryResult<
  IStripeProductItem[],
  Error
> => {
  const queryKey = `getStripeProducts_${token.id}_${mode}`;
  return useQuery([queryKey, token.id, mode], () =>
    getStripeProducts({ token, mode })
  );
};

export const useGetStripePricesQuery = ({
  token,
  mode,
  stripeProduct,
  stripePriceType,
}: IStripeProductPricesQueryParams): UseQueryResult<
  IStripeProductItem[],
  Error
> => {
  const queryKey = `getStripePrices_${token.id}_${mode}_${stripeProduct.id}_${stripePriceType}`;
  return useQuery(
    [queryKey, token.id, mode, stripeProduct, stripePriceType],
    () => getStripePrices({ token, mode, stripeProduct, stripePriceType })
  );
};
