import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function FeeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.2811 5.265C11.6562 4.88992 12.1649 4.67921 12.6953 4.67921L17.3207 4.6792C18.4253 4.6792 19.3207 5.57463 19.3207 6.6792L19.3207 11.3046C19.3207 11.8351 19.11 12.3438 18.7349 12.7188L12.7188 18.7349C11.9378 19.516 10.6714 19.516 9.8904 18.7349L5.26499 14.1095C4.48394 13.3285 4.48394 12.0622 5.26499 11.2811L11.2811 5.265Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="15.186"
        cy="8.81396"
        r="1.25"
        transform="rotate(-180 15.186 8.81396)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
