import React from "react";
import { CountryContext } from "../contexts/countryContext";
const useCountry = () => {
  const context = React.useContext(CountryContext);
  if (context === undefined) {
    throw new Error(`useCountry must be used within a CountryProvider`);
  }
  return context;
};

export default useCountry;
