import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EradCardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <rect
        x="2"
        y="5.49474"
        width="20"
        height="14"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="17.5" cy="14.9947" r="1.5" stroke="currentColor" />
      <circle cx="15.5" cy="14.9947" r="1.5" stroke="currentColor" />
      <path
        d="M5 9.49474H19"
        stroke="currentColor"
        strokeWidth="2"
        fill="currentColor"
      />
      <path
        d="M5 13.4947H10"
        stroke="currentColor"
        strokeWidth="2"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
