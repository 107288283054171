import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import useTitle from "../../hooks/useTitle";
import Page from "../Page";
import { Container } from "@mui/material";
import OnboardingStep, { OnboardingStepsEnum } from "../OnboardingStep";
import { PATH_ONBOARDING } from "../Routes/paths";

const OnboardingPages = () => {
  const { t } = useTranslation();
  const { setTitle } = useTitle();
  const location = useLocation();

  let onboardingStep: OnboardingStepsEnum;
  if (location.pathname === PATH_ONBOARDING.verification) {
    onboardingStep = OnboardingStepsEnum.VERIFY_BUSINESS;
  } else if (location.pathname === PATH_ONBOARDING.underReview) {
    onboardingStep = OnboardingStepsEnum.FUNDING_REVIEW;
  } else if (location.pathname === PATH_ONBOARDING.additionalDocumentsKSA) {
    onboardingStep = OnboardingStepsEnum.VERIFY_BUSINESS;
  } else if (location.pathname === PATH_ONBOARDING.applicationOverview) {
    onboardingStep = OnboardingStepsEnum.APPLICATION_OVERVIEW;
  } else {
    onboardingStep = OnboardingStepsEnum.CONNECT_DATA;
  }

  useEffect(() => {
    setTitle(t("pages.capital.title"));
  }, [setTitle, t]);

  return (
    <Page title={t("pages.capital.title")}>
      <Container sx={{ my: 5 }}>
        <OnboardingStep step={onboardingStep} />
        <Outlet />
      </Container>
    </Page>
  );
};

export default OnboardingPages;
