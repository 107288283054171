import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SuccessIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <circle cx="20" cy="20.665" r="14" fill="#05F1D8" />
      <path
        d="M13 20.1048L18.25 25.5648L27 16.4648"
        stroke="white"
        strokeWidth="2.8"
      />
    </SvgIcon>
  );
}
