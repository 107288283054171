import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HealthIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1372_7862)">
          <rect
            width="24"
            height="24"
            transform="translate(0.5)"
            fill="white"
          />
          <path
            d="M3.5 8.5V19.5C3.5 20.6046 4.39543 21.5 5.5 21.5H19.5C20.6046 21.5 21.5 20.6046 21.5 19.5V8.5"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M1.5 10.5L11.3237 3.35552C12.025 2.84549 12.975 2.84549 13.6763 3.35552L23.5 10.5"
            stroke="#062F41"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path d="M12.5 9.24219V17.2422" stroke="#062F41" stroke-width="2" />
          <path
            d="M16.5 13.2422L8.5 13.2422"
            stroke="#062F41"
            stroke-width="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_1372_7862">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
