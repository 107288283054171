import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import {
  BankAccountDetails,
  Company,
  CompanyDetail,
  PostDeploymentValues,
} from "../types/Company";
import { fetch } from "../utils/dataAccess";
import ViolationError from "../utils/violationError";

interface CompanyDetailsUpdateProps {
  companyIri: string;
  values: BankAccountDetails;
}

interface CompanyDetailUpdateProps {
  companyIri: string;
  values: CompanyDetail;
}
interface CompanyPostOfferKycDocumentProps {
  companyIri: string;
  values: PostDeploymentValues;
}

export const getCompany = async (company: string): Promise<Company> => {
  const { data } = await fetch({ url: company });
  return data;
};

export const useCompanyQuery = (
  company: string
): UseQueryResult<Company, Error> => {
  return useQuery(["getCompany", company], () => getCompany(company), {
    enabled: !!company,
  });
};

export const connectSystems = async ({
  id,
}: {
  id: string;
}): Promise<Company> => {
  const { data } = await fetch({
    url: `/companies/${id}/connect-systems`,
    method: "PUT",
    data: {
      areSystemsConnected: true,
    },
  });
  return data;
};

export const useConnectSystems = (): UseMutationResult<
  Company,
  Error | ViolationError,
  { id: string }
> => {
  return useMutation(connectSystems);
};

export const connectSystemsDate = async ({
  id,
}: {
  id: string;
}): Promise<Company> => {
  const { data } = await fetch({
    url: `/companies/${id}/connect-systems-date`,
    method: "PUT",
    data: {
      systemsConnectedDate: new Date().toISOString(),
    },
  });
  return data;
};

export const useConnectSystemsDate = (): UseMutationResult<
  Company,
  Error | ViolationError,
  { id: string }
> => {
  return useMutation(connectSystemsDate);
};

export const companyBankDetailsPost = async ({
  companyIri,
  values,
}: CompanyDetailsUpdateProps): Promise<Company> => {
  const { data } = await fetch({
    url: companyIri,
    method: "PUT",
    data: {
      bankAccountDetails: values,
    },
  });
  return data;
};

export const useCompanyBankDetailsPost = (): UseMutationResult<
  Company,
  Error | ViolationError,
  CompanyDetailsUpdateProps
> => {
  return useMutation(companyBankDetailsPost);
};

export const getCompanyFullInfo = async ({
  cr,
}: {
  cr: string;
}): Promise<CompanyDetail> => {
  const { data } = await fetch({
    url: `/check/company/details?cr=${cr}`,
  });

  return data;
};
export const useGetCompanyFullInfo = (): UseMutationResult<
  CompanyDetail,
  Error | ViolationError,
  { cr: string }
> => {
  return useMutation(getCompanyFullInfo);
};

export const editCompanyDetail = async ({
  companyIri,
  values,
}: CompanyDetailUpdateProps): Promise<Company> => {
  const { data } = await fetch({
    url: companyIri,
    method: "PUT",
    data: values,
  });
  return data;
};
export const editCompanyPostOfferKycDocuments = async ({
  companyIri,
  values,
}: CompanyPostOfferKycDocumentProps): Promise<Company> => {
  const shareholders = await fetch({
    url: `${companyIri}/shareholders`,
    method: "PATCH",
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
    data: {
      companyShareholders: values.companyShareholders,
    },
  });

  const directors = await fetch({
    url: `${companyIri}/directors`,
    method: "PATCH",
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
    data: {
      companyDirectors: values.companyDirectors,
    },
  });

  return shareholders.data + directors.data;
};

export const useEditCompanyDetail = (): UseMutationResult<
  Company,
  Error | ViolationError,
  CompanyDetailUpdateProps
> => {
  return useMutation(editCompanyDetail);
};

export const useEditCompanyPostOfferKycDocuments = (): UseMutationResult<
  Company,
  Error | ViolationError,
  CompanyPostOfferKycDocumentProps
> => {
  return useMutation(editCompanyPostOfferKycDocuments);
};

export const editCompanyCountry = async ({
  companyIri,
  values,
}: CompanyDetailUpdateProps): Promise<Company> => {
  const { data } = await fetch({
    url: `${companyIri}/country`,
    method: "PUT",
    data: values,
  });
  return data;
};

export const useEditCompanyCountry = (): UseMutationResult<
  Company,
  Error | ViolationError,
  CompanyDetailUpdateProps
> => {
  return useMutation(editCompanyCountry);
};
