import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TenureIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7279 2.38965C15.8635 2.38965 19.3913 5.00251 20.7459 8.66992L19.1821 9.17779L22.9491 12.5706L24.0039 7.61185L22.6494 8.05175C21.034 3.58427 16.7552 0.389648 11.7279 0.389648C5.31566 0.389648 0.117529 5.58779 0.117529 12C0.117529 18.4122 5.31566 23.6104 11.7279 23.6104C16.8838 23.6104 21.2524 20.2502 22.7681 15.603L20.8667 14.9828C19.6114 18.8314 15.9929 21.6104 11.7279 21.6104C6.42023 21.6104 2.11753 17.3077 2.11753 12C2.11753 6.69235 6.42023 2.38965 11.7279 2.38965ZM12.7562 4.509V12.0734V13.0734H11.7562H6.7147V11.0734H10.7562V4.509H12.7562Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
