import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import { convertBytesToMbsOrKbs } from "../../utils/file";
import SingleFileNameStyle from "./SingleFileNameStyle";

export interface SingleFileProps {
  file: File;
  onRemove: (file: File) => void;
  sx?: SxProps<Theme>;
}

const UploadErrorFile = ({ file, onRemove, sx = [] }: SingleFileProps) => {
  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      <Stack direction="row" alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexGrow: 1 }}
        >
          <Box>
            <SingleFileNameStyle variant="subtitle2" color="error.light">
              {file.name}
            </SingleFileNameStyle>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="error.light">
              {convertBytesToMbsOrKbs(file.size!)}
            </Typography>
          </Box>
        </Stack>

        <Box sx={{ ml: 2 }}>
          <IconButton size="small" onClick={() => onRemove(file)}>
            <Close sx={{ color: "error.light" }} />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default UploadErrorFile;
