import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function EcommerceIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" transform="translate(0.5)" fill="white" />
        <path
          d="M2.2199 9.19901C2.10216 8.02164 3.02673 7 4.20998 7H20.79C21.9733 7 22.8978 8.02164 22.7801 9.19901L21.6801 20.199C21.5779 21.2214 20.7175 22 19.69 22H5.30998C4.28247 22 3.42214 21.2214 3.3199 20.199L2.2199 9.19901Z"
          stroke="#062F41"
          stroke-width="2"
        />
        <path
          d="M17.5 11V7C17.5 4.23858 15.2614 2 12.5 2V2C9.73858 2 7.5 4.23858 7.5 7V11"
          stroke="#062F41"
          stroke-width="2"
        />
      </svg>
    </SvgIcon>
  );
}
