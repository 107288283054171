import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TransactionsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5 23L19 23C20.1046 23 21 22.1046 21 21L21 12.5L21 8.82843C21 8.29799 20.7893 7.78929 20.4142 7.41421L18 5L15.5858 2.58579C15.2107 2.21071 14.702 2 14.1716 2L12 2L5 2C3.89543 2 3 2.89543 3 4L3 21C3 22.1046 3.89543 23 5 23Z"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M11 17.5L13 19.5L17.5 15"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M6 6H12" stroke="currentColor" strokeWidth="2" />
      <path d="M6 10H18" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
}
