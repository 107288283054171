import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function OnboardingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M10.0308 8.25V6C10.0308 4.89543 10.9262 4 12.0308 4H20.9999C22.1045 4 22.9999 4.89543 22.9999 6V19C22.9999 20.1046 22.1045 21 20.9999 21H12.0308C10.9262 21 10.0308 20.1046 10.0308 19V16.75"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M8.99989 12.5H18.5991M18.5991 12.5L14.0991 8M18.5991 12.5L14.0991 17"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path d="M2 12.5H4" stroke="currentColor" strokeWidth="2" />
      <path d="M1.62221e-08 12.5H1" stroke="currentColor" strokeWidth="2" />
      <path d="M5 12.5H8" stroke="currentColor" strokeWidth="2" />
    </SvgIcon>
  );
}
