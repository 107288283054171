import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type PoWNotificationModalType = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
};

export default function PoWNotificationModal({
  opened,
  setOpened,
}: PoWNotificationModalType) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        open={opened}
        maxWidth="sm"
        PaperProps={{ elevation: 1 }}
        onClose={() => setOpened(false)}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 5, px: 5, pb: 1.5 }}
        >
          <Typography variant="h3" id="form-dialog-title">
            {t("pages.verification.proof_of_ownership.notification.heading")}
          </Typography>

          <IconButton disableRipple={true} onClick={() => setOpened(false)}>
            <Close sx={{ width: "20px", height: "20px" }} />
          </IconButton>
        </Stack>

        <DialogContent sx={{ mb: 5 }}>
          <DialogContentText
            variant="body2"
            id="form-dialog-description"
            sx={{ pb: 4, color: "text.secondary", border: "none" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: t(
                  "pages.verification.proof_of_ownership.notification.text"
                ),
              }}
            />
          </DialogContentText>
          <Button
            type="button"
            variant="contained"
            size="large"
            onClick={() => setOpened(false)}
          >
            {t("connectors.notifications.sales_extract_check.button_title")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
