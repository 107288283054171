import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function AdminIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.254883 15.8656L0.761901 15.8656L0.761901 21.2861L2.7619 21.2861L2.7619 15.8656L20.2908 15.8656L20.2908 21.2861L22.2908 21.2861L22.2908 15.8656L23.3076 15.8656L23.3076 13.8656L-0.254883 13.8656L-0.254883 15.8656ZM11.3389 8.62054C8.46615 8.62054 6.34375 10.6662 6.34375 12.9495L4.34375 12.9495C4.34375 10.0759 6.40857 7.76003 9.14681 6.94027C8.25301 6.27202 7.67444 5.20532 7.67444 4.00348C7.67444 1.97964 9.31509 0.338989 11.3389 0.338989C13.3628 0.338989 15.0034 1.97964 15.0034 4.00348C15.0034 5.20534 14.4248 6.27206 13.531 6.9403C16.2692 7.7601 18.334 10.0759 18.334 12.9495L16.334 12.9495C16.334 10.6662 14.2116 8.62054 11.3389 8.62054ZM11.3389 2.33899C10.4197 2.33899 9.67444 3.08421 9.67444 4.00348C9.67444 4.92275 10.4197 5.66797 11.3389 5.66797C12.2582 5.66797 13.0034 4.92275 13.0034 4.00348C13.0034 3.08421 12.2582 2.33899 11.3389 2.33899Z"
        fill="black"
        fill-opacity="0.6"
      />
      <circle
        cx="15.186"
        cy="8.81396"
        r="1.25"
        transform="rotate(-180 15.186 8.81396)"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
