import { Stack, styled, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { PATH_DEPLOYMENT } from "../Routes/paths";
import useAuth from "../../hooks/useAuth";

const Dot = styled("span", {
  shouldForwardProp: (prop) => prop !== "active" && prop !== "current",
})<{ active: boolean; current: number }>(({ active, current }) => ({
  width: "100%",
  height: 4,
  backgroundColor: current ? "#03A9F4" : active ? "#05F1D8" : "#E0E0E0",
  borderRadius: "3px",
}));

export enum DeploymentStepsEnum {
  ACCEPT_OFFER = "accept_offer",
  SUBMIT_KYC = "submit_kyc",
  SIGN_CONTRACT = "sign_contract",
}

type DeploymentStepType = {
  step?: DeploymentStepsEnum;
  sx?: SxProps<Theme>;
};

const DeploymentStep: React.FC<DeploymentStepType> = ({
  step = DeploymentStepsEnum.ACCEPT_OFFER,
  sx = [],
}) => {
  const { t } = useTranslation();
  const { company } = useAuth();

  const steps = {
    [DeploymentStepsEnum.ACCEPT_OFFER]: {
      id: 1,
      title: t("stepper_deployment.steps.accept_offer"),
      countries: ["AE", "SA"],
    },
    [DeploymentStepsEnum.SUBMIT_KYC]: {
      id: 2,
      title: t("stepper_deployment.steps.submit_kyc"),
      countries: ["AE"],
    },
    [DeploymentStepsEnum.SIGN_CONTRACT]: {
      id: 3,
      title: t("stepper_deployment.steps.sign_contract"),
      countries: ["AE", "SA"],
    },
  };

  const countryCode = company?.countryCode || "";
  const selectedStepId = steps[step].id;
  let stepIndex = 1;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      mb={2}
    >
      {Object.values(steps).map((item, index) => {
        if (!item.countries.includes(countryCode)) {
          return false;
        }

        return (
          <Stack key={"step_stack_" + index} sx={{ width: "33%" }}>
            <Dot
              active={item.id <= selectedStepId}
              current={item.id === selectedStepId ? 1 : 0}
            />

            <Typography variant="h5" mt={2}>
              {t("stepper.heading")} {stepIndex++}
            </Typography>

            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {item.title}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default DeploymentStep;
