import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function RetailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1372_7854)">
          <rect
            width="24"
            height="24"
            transform="translate(0.5)"
            fill="white"
          />
          <path
            d="M4.5 13V20C4.5 21.1046 5.39543 22 6.5 22H18.5C19.6046 22 20.5 21.1046 20.5 20V13"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M9.5 22V18C9.5 16.8954 10.3954 16 11.5 16H13.5C14.6046 16 15.5 16.8954 15.5 18V22"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.88607 2C5.05238 2 4.30614 2.51715 4.01341 3.29775L2.31117 7.83709C1.77482 9.26735 1.30398 10.9259 2.20392 12.1602V12.1602C2.66136 12.7876 3.30615 13.2536 4.04539 13.491C4.78463 13.7284 5.58017 13.7251 6.31738 13.4814C7.05459 13.2377 7.69542 12.7663 8.14754 12.1351C8.59309 11.513 8.83271 10.7674 8.8333 10.0029C8.83338 10.092 8.83669 10.1812 8.8433 10.2706C8.89908 11.0243 9.18643 11.7424 9.66602 12.3266C10.1456 12.9108 10.794 13.3325 11.5225 13.534C12.251 13.7354 13.0239 13.7069 13.7355 13.4522C14.4472 13.1975 15.0627 12.7291 15.4979 12.1111C15.933 11.4932 16.1666 10.7558 16.1666 10C16.1666 10.7656 16.4064 11.5123 16.8525 12.1351C17.3046 12.7663 17.9454 13.2377 18.6826 13.4814C19.4198 13.7251 20.2154 13.7284 20.9546 13.491C21.6939 13.2536 22.3386 12.7876 22.7961 12.1602V12.1602C23.696 10.9259 23.2253 9.26737 22.6889 7.8371L20.9867 3.29775C20.6939 2.51715 19.9477 2 19.114 2H5.88607Z"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M16.1748 10.1193L15.3125 2.03516"
            stroke="#062F41"
            stroke-width="2"
          />
          <path
            d="M8.82524 10.1193L9.6875 2.03516"
            stroke="#062F41"
            stroke-width="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_1372_7854">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
