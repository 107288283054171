import { Box } from "@mui/material";
import { t } from "i18next";
import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { isLngAR } from "../../utils/i18n";

const Page = forwardRef(
  (
    {
      children,
      title = "",
      meta,
      sx,
      ...other
    }: {
      children: JSX.Element | JSX.Element[];
      title: string;
      meta?: JSX.Element | JSX.Element[];
      sx?: SxProps<Theme>;
    },
    ref
  ) => (
    <>
      <Helmet>
        <title>
          {isLngAR()
            ? `${title} | ${t("meta.title")}`
            : `${title} | ${t("meta.title")}`}
        </title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other} sx={sx}>
        {children}
      </Box>
    </>
  )
);

export default Page;
