import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BtoBIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" transform="translate(0.5)" fill="white" />
        <rect
          x="10.5"
          y="9"
          width="12"
          height="12"
          rx="2"
          stroke="#062F41"
          stroke-width="2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 5C1.5 3.34315 2.84315 2 4.5 2H12.5C14.1569 2 15.5 3.34315 15.5 5V7H13.5V5C13.5 4.44772 13.0523 4 12.5 4H4.5C3.94772 4 3.5 4.44772 3.5 5V19C3.5 19.5523 3.94772 20 4.5 20H8.62602C8.82838 20.7862 9.26377 21.4789 9.85418 22H4.5C2.84315 22 1.5 20.6569 1.5 19V5ZM11.5 7.12602C10.8806 7.28544 10.3193 7.58948 9.85418 8H6C5.72386 8 5.5 7.77614 5.5 7.5V6.5C5.5 6.22386 5.72386 6 6 6H11C11.2761 6 11.5 6.22386 11.5 6.5V7.12602Z"
          fill="#062F41"
        />
        <rect x="13.5" y="12" width="6" height="2" rx="0.5" fill="#062F41" />
      </svg>
    </SvgIcon>
  );
}
