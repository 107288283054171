import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function HistoryIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_1779_28125)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.25797 9.16992C4.61265 5.50251 8.14043 2.88965 12.276 2.88965C17.5837 2.88965 21.8864 7.19235 21.8864 12.5C21.8864 17.8077 17.5837 22.1104 12.276 22.1104C8.01103 22.1104 4.39249 19.3314 3.13725 15.4828L1.23582 16.103C2.75154 20.7502 7.12007 24.1104 12.276 24.1104C18.6882 24.1104 23.8864 18.9122 23.8864 12.5C23.8864 6.08779 18.6882 0.889648 12.276 0.889648C7.24867 0.889648 2.96995 4.08427 1.35453 8.55175L0 8.11185L1.05476 13.0706L4.8218 9.67779L3.25797 9.16992Z"
          fill="currentColor"
        />
        <path
          d="M12.0964 8.77881V12.9759L16.3521 17.2316"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1779_28125">
          <rect width="24" height="24" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
