import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SettlementsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0002 4.85911L8.86388 3.72278L7.80322 4.78344L9.25022 6.23044V8.62175C9.13104 8.66062 9.01593 8.7085 8.9057 8.76459L7.21469 7.07358V5.02722L5.71469 5.02722L5.71469 6.63424L4.10767 6.63424L4.10767 8.13424H6.15403L7.84504 9.82525C7.78895 9.93548 7.74106 10.0506 7.70219 10.1698H5.31088L3.86388 8.72278L2.80322 9.78344L3.93956 10.9198L2.80322 12.0561L3.86388 13.1168L5.31088 11.6698H7.70219C7.74106 11.789 7.78895 11.9041 7.84504 12.0143L6.15403 13.7053H4.10766V15.2053H5.71469V16.8123H7.21469V14.766L8.9057 13.075C9.01593 13.131 9.13104 13.1789 9.25022 13.2178V15.6091L7.80322 17.0561L8.86388 18.1168L10.0002 16.9804L11.1366 18.1168L12.1972 17.0561L10.7502 15.6091V13.2178C10.8694 13.1789 10.9845 13.131 11.0947 13.075L12.7858 14.766L12.7858 16.8123H14.2858V15.2053H15.8928V13.7053H13.8464L12.1554 12.0143C12.2115 11.9041 12.2594 11.789 12.2982 11.6698H14.6896L16.1366 13.1168L17.1972 12.0561L16.0609 10.9198L17.1972 9.78344L16.1366 8.72278L14.6896 10.1698L12.2982 10.1698C12.2594 10.0506 12.2115 9.93548 12.1554 9.82525L13.8464 8.13424H15.8928V6.63424H14.2858V5.02722H12.7858V7.07358L11.0947 8.76459C10.9845 8.7085 10.8694 8.66062 10.7502 8.62175V6.23044L12.1972 4.78344L11.1366 3.72278L10.0002 4.85911ZM10.9169 10.9198C10.9169 10.4135 10.5065 10.0031 10.0002 10.0031C9.49396 10.0031 9.08355 10.4135 9.08355 10.9198C9.08355 11.426 9.49396 11.8364 10.0002 11.8364C10.5065 11.8364 10.9169 11.426 10.9169 10.9198Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
