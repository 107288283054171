import React, { useMemo, useState } from "react";

interface SearchContextValue {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const SearchContext = React.createContext({} as SearchContextValue);
SearchContext.displayName = "SearchContext";

const SearchProvider: React.FC = (props) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const value = useMemo(
    () => ({
      searchValue,
      setSearchValue,
    }),
    [searchValue, setSearchValue]
  );

  return <SearchContext.Provider value={value} {...props} />;
};

export { SearchContext, SearchProvider };
