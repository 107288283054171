import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CapitalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <rect
        x="5.75"
        y="9.7655"
        width="17.1885"
        height="10.9381"
        rx="2"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M18.2505 7.42156V6.29639C18.2505 5.19182 17.3551 4.29639 16.2505 4.29639H3.06201C1.95744 4.29639 1.06201 5.19182 1.06201 6.29639V13.2345C1.06201 14.3391 1.95744 15.2345 3.06201 15.2345H3.40589"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="14.3439"
        cy="15.2345"
        r="2.34388"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle cx="19.813" cy="15.2345" r="0.781294" fill="currentColor" />
      <circle cx="8.87504" cy="15.2345" r="0.781294" fill="currentColor" />
    </SvgIcon>
  );
}
