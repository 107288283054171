import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CardDetailsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 22 21">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59082 0C1.93397 0 0.59082 1.34314 0.59082 3V12.7945C0.59082 14.3737 1.87101 15.6539 3.45022 15.6539V13.6539C2.97558 13.6539 2.59082 13.2692 2.59082 12.7945V8.37729H6.22007C7.47544 7.21095 9.15757 6.49769 11.0063 6.49769C12.855 6.49769 14.5371 7.21095 15.7925 8.37729H19.4095V12.6572C19.4095 13.2077 18.9633 13.6539 18.4129 13.6539V15.6539C20.0679 15.6539 21.4095 14.3123 21.4095 12.6572V8.37287V3C21.4095 1.34315 20.0664 0 18.4095 0H3.59082ZM19.4095 5.37729H2.59082V3C2.59082 2.44771 3.03853 2 3.59082 2H18.4095C18.9618 2 19.4095 2.44772 19.4095 3V5.37729ZM7.97278 13.5312C7.97278 11.8558 9.33093 10.4977 11.0063 10.4977C12.6816 10.4977 14.0398 11.8558 14.0398 13.5312C14.0398 15.2066 12.6816 16.5647 11.0063 16.5647C9.33093 16.5647 7.97278 15.2066 7.97278 13.5312ZM11.0063 8.49769C8.22636 8.49769 5.97278 10.7513 5.97278 13.5312C5.97278 16.3111 8.22636 18.5647 11.0063 18.5647C12.0181 18.5647 12.9601 18.2662 13.7492 17.7524L16.9858 20.989L18.4 19.5748L15.1763 16.3511C15.7214 15.5467 16.0398 14.5761 16.0398 13.5312C16.0398 10.7513 13.7862 8.49769 11.0063 8.49769Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
