import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <circle cx="20" cy="20.665" r="14" fill="#FC0635" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.665C18.7863 12.665 17.8378 13.713 17.9586 14.9208L18.7538 22.8721C18.8178 23.5124 19.3566 23.9999 20 23.9999C20.6435 23.9999 21.1823 23.5124 21.2463 22.8721L22.0414 14.9208C22.1622 13.713 21.2138 12.665 20 12.665ZM19.9999 28.6659C20.8918 28.6659 21.6147 27.9429 21.6147 27.0511C21.6147 26.1593 20.8918 25.4363 19.9999 25.4363C19.1081 25.4363 18.3852 26.1593 18.3852 27.0511C18.3852 27.9429 19.1081 28.6659 19.9999 28.6659Z"
        fill="white"
      />
    </SvgIcon>
  );
}
