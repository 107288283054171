import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CreditCardVisaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.317 2.72095H11.6572V18.279H20.317V2.72095Z"
          fill="#FF5A00"
        />
        <path
          d="M12.2338 10.4999C12.2338 7.33899 13.7198 4.53355 16 2.7209C14.3219 1.40144 12.205 0.604004 9.89592 0.604004C4.42594 0.604004 0 5.02994 0 10.4999C0 15.9699 4.42594 20.3958 9.89592 20.3958C12.205 20.3958 14.3219 19.5984 16 18.2789C13.7166 16.4919 12.2338 13.6608 12.2338 10.4999Z"
          fill="#EB001B"
        />
        <path
          d="M32 10.4999C32 15.9699 27.5741 20.3958 22.1041 20.3958C19.795 20.3958 17.6781 19.5984 16 18.2789C18.309 16.4631 19.7662 13.6608 19.7662 10.4999C19.7662 7.33899 18.2802 4.53355 16 2.7209C17.6749 1.40144 19.7918 0.604004 22.1009 0.604004C27.5741 0.604004 32 5.05877 32 10.4999Z"
          fill="#F79E1B"
        />
      </svg>
    </SvgIcon>
  );
}
