import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function CreditCardVisaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 31 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.4331 10.5176H10.9219L12.4926 0.514648H15.0036L13.4331 10.5176Z"
          fill="#00579F"
        />
        <path
          d="M22.5369 0.759117C22.0415 0.556721 21.2559 0.333252 20.2845 0.333252C17.8046 0.333252 16.0583 1.69528 16.0476 3.64258C16.027 5.07929 17.2979 5.87727 18.2484 6.35631C19.22 6.84583 19.5502 7.16533 19.5502 7.60166C19.5404 8.27179 18.7652 8.58068 18.0422 8.58068C17.0396 8.58068 16.5024 8.42143 15.686 8.0486L15.3553 7.88878L15.0039 10.1342C15.5929 10.4106 16.678 10.6558 17.8046 10.6666C20.4395 10.6666 22.155 9.32563 22.1753 7.25048C22.1853 6.11177 21.5142 5.23925 20.0674 4.52627C19.1891 4.06858 18.6512 3.75997 18.6512 3.29167C18.6615 2.86595 19.1061 2.4299 20.0976 2.4299C20.914 2.40855 21.5138 2.61066 21.9682 2.81291L22.1953 2.91913L22.5369 0.759117Z"
          fill="#00579F"
        />
        <path
          d="M25.8745 6.9739C26.0813 6.39924 26.8771 4.17516 26.8771 4.17516C26.8667 4.19652 27.0835 3.5899 27.2075 3.21749L27.383 4.07941C27.383 4.07941 27.8586 6.47378 27.9618 6.9739C27.5694 6.9739 26.3705 6.9739 25.8745 6.9739ZM28.9743 0.514648H27.0319C26.4329 0.514648 25.9778 0.695404 25.7193 1.3446L21.9893 10.5174H24.6242C24.6242 10.5174 25.058 9.28285 25.1512 9.01695C25.4403 9.01695 28.0036 9.01695 28.3754 9.01695C28.4475 9.36813 28.6751 10.5174 28.6751 10.5174H31.0002L28.9743 0.514648Z"
          fill="#00579F"
        />
        <path
          d="M8.82448 0.514648L6.36518 7.33569L6.09644 5.95231C5.64176 4.35606 4.2158 2.62177 2.62451 1.75943L4.87715 10.507H7.53269L11.4799 0.514648H8.82448Z"
          fill="#00579F"
        />
        <path
          d="M4.08164 0.514648H0.0413339L0 0.716761C3.15169 1.54685 5.23899 3.54775 6.09657 5.95273L5.21825 1.35549C5.07365 0.716619 4.62928 0.535722 4.08164 0.514648Z"
          fill="#FAA61A"
        />
      </svg>
    </SvgIcon>
  );
}
