import { PATH_ONBOARDING } from "../Routes/paths";

export enum CONNECTOR_STEPS {
  SALES_SOFTWARE = "Sales software",
  MARKETING_CHANNELS = "Marketing channels",
  BANK_ACCOUNT = "Bank account",
  COMPANY_DOCUMENTS = "Company Documents",
  ACCOUNTING_SOFTWARE = "Accounting software",
  DEBT_AGREEMENT = "Company debt agreements",
  PURCHASE_ORDER = "Purchase orders",
}

export const steps = [
  CONNECTOR_STEPS.SALES_SOFTWARE,
  CONNECTOR_STEPS.MARKETING_CHANNELS,
  CONNECTOR_STEPS.BANK_ACCOUNT,
  CONNECTOR_STEPS.COMPANY_DOCUMENTS,
  CONNECTOR_STEPS.ACCOUNTING_SOFTWARE,
  CONNECTOR_STEPS.DEBT_AGREEMENT,
  CONNECTOR_STEPS.PURCHASE_ORDER,
];

export const stepsDetails = {
  [CONNECTOR_STEPS.SALES_SOFTWARE]: {
    id: 1,
    required: true,
    summary_title: "pages.steps_details.sales_software.title",
    summary_desc: "pages.steps_details.sales_software.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 0,
  },
  [CONNECTOR_STEPS.MARKETING_CHANNELS]: {
    id: 2,
    required: true,
    summary_title: "pages.steps_details.marketing_channels.title",
    summary_desc: "pages.steps_details.marketing_channels.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 1,
  },
  [CONNECTOR_STEPS.BANK_ACCOUNT]: {
    id: 3,
    required: true,
    summary_title: "pages.steps_details.bank_account.title",
    summary_desc: "pages.steps_details.bank_account.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 3,
  },
  [CONNECTOR_STEPS.COMPANY_DOCUMENTS]: {
    id: 4,
    required: true,
    summary_title: "pages.steps_details.company_documents.title",
    summary_desc: "",
    path: PATH_ONBOARDING.additionalDocumentsKSA,
    countries: ["SA"],
    step: 3,
    subStep: 1,
  },
  [CONNECTOR_STEPS.ACCOUNTING_SOFTWARE]: {
    id: 5,
    required: false,
    summary_title: "pages.steps_details.accounting_software.title",
    summary_desc: "pages.steps_details.accounting_software.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 2,
  },
  [CONNECTOR_STEPS.DEBT_AGREEMENT]: {
    id: 6,
    required: false,
    summary_title: "pages.steps_details.debt_agreement.title",
    summary_desc: "pages.steps_details.debt_agreement.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 4,
  },
  [CONNECTOR_STEPS.PURCHASE_ORDER]: {
    id: 7,
    required: false,
    summary_title: "pages.steps_details.purchase_order.title",
    summary_desc: "pages.steps_details.purchase_order.summary",
    path: PATH_ONBOARDING.connectSystems,
    countries: ["SA", "AE"],
    step: 2,
    subStep: 5,
  },
};
