import React, { useEffect } from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateAsyncFunction,
  useQueryClient,
} from "react-query";
import { useLogin, useLogout } from "../queries/auth";
import { useCompanyQuery } from "../queries/company";
import { useMeQuery } from "../queries/user";
import { Company } from "../types/Company";
import { LoginCredentials } from "../types/LoginCredentials";
import { Token } from "../types/Token";
import { User } from "../types/User";
import { setSession } from "../utils/jwt";
import ViolationError from "../utils/violationError";

interface AuthContextValue {
  isInitialized: boolean;
  isCompanyInitialized: boolean;
  isAuthenticated: boolean;
  isPhoneVerified: boolean;
  user: User | undefined;
  company: Company | undefined;
  login: UseMutateAsyncFunction<
    Token,
    Error | ViolationError,
    LoginCredentials
  >;
  logout: UseMutateAsyncFunction<any, any, void, any>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<User, Error>>;
  refetchCompany: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Company, Error>>;
  setUser: (data: User) => User;
  setCompany: (data: Company) => Company;
  error: Error | null;
  companyError: Error | null;
}

const AuthContext = React.createContext<AuthContextValue | null>(null);
AuthContext.displayName = "AuthContext";

const AuthProvider: React.FC = (props) => {
  const queryClient = useQueryClient();
  const { data: user, error, isSuccess, isError, refetch } = useMeQuery();
  const {
    data: company,
    error: companyError,
    isSuccess: isCompanySuccess,
    isError: isCompanyError,
    refetch: refetchCompany,
  } = useCompanyQuery(user?.company || "");

  const setUser = React.useCallback(
    (data: User) => queryClient.setQueryData("getMe", data),
    [queryClient]
  );

  const setCompany = React.useCallback(
    (data: Company) =>
      queryClient.setQueryData(["getCompany", data["@id"]], data),
    [queryClient]
  );
  const loginMutation = useLogin(refetch);
  const logoutMutation = useLogout();

  const value = React.useMemo(
    () => ({
      isInitialized: isSuccess || isError,
      isCompanyInitialized: isCompanySuccess || isCompanyError,
      isAuthenticated: !!user,
      isPhoneVerified: !!user?.isPhoneVerified,
      isCompanyToldAboutBusiness: !!user?.isCompanyToldAboutBusiness,
      user,
      company,
      error,
      companyError,
      refetchUser: refetch,
      refetchCompany,
      setUser,
      setCompany,
      login: loginMutation.mutateAsync,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutateAsync,
      isLoggingOut: logoutMutation.isLoading,
    }),
    [
      isSuccess,
      isError,
      isCompanySuccess,
      isCompanyError,
      user,
      company,
      error,
      companyError,
      refetch,
      refetchCompany,
      setUser,
      setCompany,
      loginMutation.mutateAsync,
      loginMutation.isLoading,
      logoutMutation.mutateAsync,
      logoutMutation.isLoading,
    ]
  );

  useEffect(() => {
    const storageEventListener = async (event: StorageEvent) => {
      // Refetch user when we receive a login event from another window.
      if (event.key === "app-login") {
        await refetch();
      }

      // Logout user when we receive a logout event from another window.
      if (event.key === "app-logout") {
        setSession(null);
        queryClient.clear();
        await refetch();
      }
    };

    window.addEventListener("storage", storageEventListener);

    return () => {
      window.removeEventListener("storage", storageEventListener);
    };
  }, [queryClient, refetch]);

  return <AuthContext.Provider value={value} {...props} />;
};

export { AuthContext, AuthProvider };
