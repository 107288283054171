import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import useAuth from "../hooks/useAuth";
import Collection from "../types/Collection";
import {
  Connector,
  ConnectorType,
  ConnectorTypeCategory,
  CsvConnectorConfigurationData,
  SallaConnector,
} from "../types/Connector";
import { fetch } from "../utils/dataAccess";
import ViolationError from "../utils/violationError";

export const getConnectorTypeCategory = async (
  connectorTypeCategory: string
): Promise<ConnectorTypeCategory> => {
  const { data } = await fetch({ url: connectorTypeCategory });
  return data;
};

export const useConnectorTypeCategoryQuery = (
  connectorTypeCategory: string
): UseQueryResult<ConnectorTypeCategory, Error> => {
  return useQuery(
    ["getConnectorTypeCategory", connectorTypeCategory],
    () => getConnectorTypeCategory(connectorTypeCategory),
    {
      enabled: !!connectorTypeCategory,
    }
  );
};

export const getConnectorTypeCategories = async (): Promise<
  Collection<ConnectorTypeCategory>
> => {
  const { data } = await fetch({ url: "/connector_type_categories?enabled=1" });
  return data;
};

export const useConnectorTypeCategoriesQuery = (): UseQueryResult<
  Collection<ConnectorTypeCategory>,
  Error
> => {
  return useQuery("getConnectorTypeCategories", () =>
    getConnectorTypeCategories()
  );
};

export const getConnectorTypeSalla = async (): Promise<SallaConnector> => {
  const { data } = await fetch({ url: "/settings/connector-type-salla" });
  return data;
};

export const useConnectorTypeSallaQuery = (): UseQueryResult<
  SallaConnector,
  Error
> => {
  return useQuery("getConnectorTypeSalla", () => getConnectorTypeSalla());
};

export const getConnectorType = async (
  connectorType: string
): Promise<ConnectorType> => {
  const { data } = await fetch({ url: connectorType });
  return data;
};

export const useConnectorTypeQuery = (
  connectorType: string
): UseQueryResult<ConnectorType, Error> => {
  return useQuery(
    ["getConnectorType", connectorType],
    () => getConnectorType(connectorType),
    {
      enabled: !!connectorType,
    }
  );
};

export const useConnectorTypesFromCategoryQuery = (
  connectorTypes: Array<string>
): UseQueryResult<ConnectorType, unknown>[] => {
  return useQueries(
    connectorTypes.map((connectorType) => {
      return {
        queryKey: ["getConnectorType", connectorType],
        queryFn: () => getConnectorType(connectorType),
      };
    })
  );
};

export const getConnector = async (connector: string): Promise<Connector> => {
  const { data } = await fetch({ url: connector });
  return data;
};

export const useConnectorQuery = (
  connector: string
): UseQueryResult<Connector, Error> => {
  return useQuery(["getConnector", connector], () => getConnector(connector), {
    enabled: !!connector,
  });
};

export const getConnectors = async (): Promise<Collection<Connector>> => {
  const { data } = await fetch({ url: "/connectors?connectorType.enabled=1" });
  return data;
};

export const useConnectorsQuery = (): UseQueryResult<
  Collection<Connector>,
  Error
> => {
  return useQuery("getConnectors", () => getConnectors());
};

export type syncFivetranConnectorProps = {
  companyId: string;
};

export const getConnectorsSyncWithFivetran = async (
  companyId: string
): Promise<Connector[]> => {
  const { data } = await fetch({ url: `/company/connector?id=${companyId}` });
  return data;
};

export const useFivetranSyncedConnectorsQuery = (
  companyId: string
): UseQueryResult<Connector[], Error> => {
  return useQuery(["getConnectorsSyncWithFivetran", companyId], () =>
    getConnectorsSyncWithFivetran(companyId)
  );
};

export type ConnectorsPostProps = {
  connectorType: string;
  values?: CsvConnectorConfigurationData;
};

export const connectorsPost = async ({
  connectorType,
  values,
}: ConnectorsPostProps): Promise<Connector> => {
  const { data } = await fetch({
    url: "connectors",
    method: "POST",
    data: {
      connectorType,
      configurationData: values,
    },
  });

  return data;
};

export const useConnectorsPost = (): UseMutationResult<
  Connector,
  Error | ViolationError,
  ConnectorsPostProps
> => {
  const { company } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(connectorsPost, {
    mutationKey: "connectorsPost",
    onSuccess: () => {
      if (company) {
        return queryClient.invalidateQueries(["getCompany", company["@id"]]);
      }
    },
  });
};

export type ConnectorsDeleteProps = {
  connectorId: string;
};

export const connectorsDelete = async ({
  connectorId,
}: ConnectorsDeleteProps) => {
  await fetch({
    url: `connectors/${connectorId}`,
    method: "DELETE",
  });
};

export const useConnectorsDelete = (): UseMutationResult<
  unknown,
  Error | ViolationError,
  ConnectorsDeleteProps
> => {
  return useMutation(connectorsDelete, { mutationKey: "connectorsDelete" });
};

export type updateConnectorsConfigurationDataProps = {
  connectorId: string;
  values?: CsvConnectorConfigurationData | {};
  attempts?: number;
};

export const updateConnectorsConfigurationData = async ({
  connectorId,
  values,
  attempts,
}: updateConnectorsConfigurationDataProps): Promise<Connector> => {
  let attempt = 0;
  const maxAttempts = attempts ?? 120;

  while (attempt < maxAttempts) {
    attempt++;

    try {
      const { data } = await fetch({
        url: `connectors/${connectorId}/setup-configuration-data`,
        method: "PUT",
        data: {
          configurationData: values,
        },
      });

      return data;
    } catch (error: Error | any) {
      if (error?.message) {
        throw error;
      }

      // Try again after one second for callback to be invoked.
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  }

  throw new Error("Unable to setup configuration data.");
};

export const useUpdateConnectorsConfigurationData = (): UseMutationResult<
  Connector,
  Error | ViolationError,
  updateConnectorsConfigurationDataProps
> => {
  const { company } = useAuth();
  const queryClient = useQueryClient();
  return useMutation(updateConnectorsConfigurationData, {
    mutationKey: "updateConnectorsConfiguration",
    onSuccess: () => {
      if (company) {
        return queryClient.invalidateQueries(["getCompany", company["@id"]]);
      }
    },
  });
};
