import {
  Box,
  Button,
  Container,
  Checkbox,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { CONNECTOR_STEPS, steps, stepsDetails } from "./ApplicationSteps";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useAuth from "../../hooks/useAuth";
import {
  useConnectorsQuery,
  useConnectorTypeCategoriesQuery,
} from "../../queries/connector";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RevenueSegmentOptions } from "../VerifyBusinessInfo/ChooseRevenueSegmentsForm";
import { FundPurposes } from "../VerifyBusinessInfo/ChooseFundPurposeForm";
import { getRevenueOptionDetails } from "../VerifyBusinessInfo/VerifyBusinessInfoForm";
import { PATH_ONBOARDING } from "../Routes/paths";
import { useState } from "react";
import { useConnectSystemsDate } from "../../queries/company";
import { useKycKybStatus } from "../../queries/kycKyb";

const ApplicationOverview = () => {
  const { t } = useTranslation(["translation"]);
  const { company, setCompany } = useAuth();
  const { data: connectors } = useConnectorsQuery();
  const { data: connectorTypeCategories } = useConnectorTypeCategoriesQuery();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const connectSystemsDateMutation = useConnectSystemsDate();
  const updatekycKybStatus = useKycKybStatus();
  const countryCode = company?.countryCode ?? "";

  if (company?.systemsConnectedDate && !isChecked) {
    setIsChecked(true);
  }

  const handleBack = () => {
    if (countryCode === "SA") {
      return navigate(PATH_ONBOARDING.additionalDocumentsKSA);
    }
    return navigate(PATH_ONBOARDING.verification);
  };

  let completedSteps: CONNECTOR_STEPS[] = [];
  let todoSteps = [
    CONNECTOR_STEPS.SALES_SOFTWARE,
    CONNECTOR_STEPS.MARKETING_CHANNELS,
    CONNECTOR_STEPS.BANK_ACCOUNT,
    CONNECTOR_STEPS.COMPANY_DOCUMENTS,
    CONNECTOR_STEPS.ACCOUNTING_SOFTWARE,
    CONNECTOR_STEPS.DEBT_AGREEMENT,
    CONNECTOR_STEPS.PURCHASE_ORDER,
  ];

  if (connectors && connectorTypeCategories) {
    steps.map((step) => {
      const connectorTypeCategory = connectorTypeCategories[
        "hydra:member"
      ].find((category) => category.name === step);
      if (
        connectors["hydra:member"].some(
          (connector) =>
            connector.isConnected &&
            connectorTypeCategory?.enabledConnectorTypes?.includes(
              connector.connectorType ?? ""
            )
        )
      ) {
        completedSteps.push(step);
        todoSteps = todoSteps.filter((todoStep) => todoStep !== step);
      }
    });
  }

  if (
    localStorage.getItem("companyPurchaseOrder") === "true" ||
    (company?.kycKybDocuments?.purchaseOrder ?? []).length
  ) {
    completedSteps.push(CONNECTOR_STEPS.PURCHASE_ORDER);
    todoSteps = todoSteps.filter(
      (step) => step !== CONNECTOR_STEPS.PURCHASE_ORDER
    );
  }

  let completedCompanyDocuments = true;
  if (countryCode === "SA") {
    if ((company?.kycKybDocuments?.companyConsent ?? []).length === 0) {
      completedCompanyDocuments = false;
    } else if (
      (company?.kycKybDocuments?.shareholderConsent ?? []).length === 0
    ) {
      completedCompanyDocuments = false;
    }
  } else {
    if ((company?.kycKybDocuments?.proofOfAddress ?? []).length === 0) {
      completedCompanyDocuments = false;
    } else if ((company?.companyShareholders ?? []).length === 0) {
      completedCompanyDocuments = false;
    } else if ((company?.companyDirectors ?? []).length === 0) {
      completedCompanyDocuments = false;
    } else if (
      (company?.kycKybDocuments?.memorandumOfAssociation ?? []).length === 0
    ) {
      completedCompanyDocuments = false;
    } else if (
      (company?.kycKybDocuments?.articlesOfAssociation ?? []).length === 0
    ) {
      completedCompanyDocuments = false;
    }
  }

  if (completedCompanyDocuments) {
    completedSteps.push(CONNECTOR_STEPS.COMPANY_DOCUMENTS);
    todoSteps = todoSteps.filter(
      (step) => step !== CONNECTOR_STEPS.COMPANY_DOCUMENTS
    );
  }

  if (
    localStorage.getItem("companyDebtAgreement") === "true" ||
    (company?.kycKybDocuments?.debtAgreement ?? []).length
  ) {
    completedSteps.push(CONNECTOR_STEPS.DEBT_AGREEMENT);
    todoSteps = todoSteps.filter(
      (step) => step !== CONNECTOR_STEPS.DEBT_AGREEMENT
    );
  }

  if (!company?.revenueSegments?.includes("b2b")) {
    todoSteps = todoSteps.filter(
      (step) => step !== CONNECTOR_STEPS.PURCHASE_ORDER
    );
    completedSteps = completedSteps.filter(
      (step) => step !== CONNECTOR_STEPS.PURCHASE_ORDER
    );
  }

  if (countryCode === "AE") {
    todoSteps = todoSteps.filter(
      (step) => step !== CONNECTOR_STEPS.COMPANY_DOCUMENTS
    );
    completedSteps = completedSteps.filter(
      (step) => step !== CONNECTOR_STEPS.COMPANY_DOCUMENTS
    );
  }

  todoSteps.sort((a, b) => {
    return stepsDetails[a].id - stepsDetails[b].id;
  });

  completedSteps.sort((a, b) => {
    return stepsDetails[a].id - stepsDetails[b].id;
  });

  const allRequiredCompleted = steps.every((step) => {
    if (
      stepsDetails[step].required &&
      stepsDetails[step].countries.includes(countryCode)
    ) {
      return completedSteps.includes(step);
    }
    return true;
  });

  const fundPurposesText: string[] = [];
  FundPurposes.map((fund) => {
    if (company?.fundingPurposes?.includes(fund.value)) {
      fundPurposesText.push(t(fund.label));
    }
  });

  const revenueSegmentsText: string[] = [];
  RevenueSegmentOptions.map((rev) => {
    if (company?.revenueSegments?.includes(rev.value)) {
      revenueSegmentsText.push(t(rev.label));
    }
  });

  const monthlyRevenueOption = getRevenueOptionDetails(
    company?.monthlyRevenue ?? 0
  );
  const monthlyRevenueText = monthlyRevenueOption.label;

  return (
    <Container maxWidth="lg" disableGutters>
      <Stack direction={"column"} spacing={4}>
        <Box>
          <Typography variant="h4" sx={{ mb: 1.5 }}>
            {t("pages.application_overview.heading")}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {t("pages.application_overview.subheading")}
          </Typography>
        </Box>

        <Box>
          <Stack spacing={3} direction="row" sx={{ marginBottom: "2em" }}>
            <Box sx={{ flexDirection: "row", width: "30%" }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.overview.company_name")}
              </Typography>
              {company?.name}
            </Box>

            {countryCode === "SA" && (
              <Box sx={{ flexDirection: "row", width: "30%" }}>
                <Typography variant="h5" sx={{ mb: 1.5 }}>
                  {t("pages.application_overview.overview.cr_number")}
                </Typography>
                {company?.registrationNumber}
              </Box>
            )}

            <Box sx={{ flexDirection: "row", width: "30%" }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.overview.sector")}
              </Typography>
              {revenueSegmentsText.join(", ")}
            </Box>
          </Stack>
          <Stack spacing={3} direction="row" sx={{ marginBottom: "2em" }}>
            <Box sx={{ flexDirection: "row", width: "30%" }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.overview.revenue")}
              </Typography>
              <span dir="ltr">
                {countryCode === "SA" ? "SAR" : "AED"} {monthlyRevenueText}
              </span>
            </Box>

            <Box sx={{ flexDirection: "row", width: "30%" }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.overview.offer_amount")}
              </Typography>
              {countryCode === "SA" ? "SAR" : "AED"}{" "}
              {monthlyRevenueOption?.possibleFinancing?.toLocaleString()}
              ,000.00*
            </Box>

            <Box sx={{ flexDirection: "row", width: "30%" }}>
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                {t("pages.application_overview.overview.purpose")}
              </Typography>
              {fundPurposesText.join(", ")}
            </Box>
          </Stack>
        </Box>

        {/* To-Do Section */}
        {todoSteps.length ? (
          <>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <InfoOutlinedIcon color="info" />
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "text.primary" }}
              >
                {t("pages.onboarding_overview.todo")}
              </Typography>
            </Stack>
            <Stack direction={"column"} spacing={1.5}>
              {todoSteps.map((step, index) => (
                <SummaryDetailBox
                  key={index}
                  icon_type="info"
                  label={step}
                  stepIndex={steps.indexOf(step)}
                  isSkipped={true}
                />
              ))}
            </Stack>
          </>
        ) : (
          ""
        )}

        {/* Done Section */}
        {completedSteps.length ? (
          <>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <CheckCircleOutlineIcon color="success" />
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "text.primary" }}
              >
                {t("pages.onboarding_overview.done")}
              </Typography>
            </Stack>
            <Stack direction={"column"} spacing={1.5}>
              {completedSteps.map((step, index) => (
                <SummaryDetailBox
                  key={index}
                  icon_type="done"
                  label={step}
                  stepIndex={steps.indexOf(step)}
                  isSkipped={false}
                />
              ))}
            </Stack>
          </>
        ) : (
          ""
        )}
      </Stack>

      <Stack direction={"column"} spacing={2} sx={{ marginTop: "20px" }}>
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            disabled={isChecked && company?.kycKybStatus !== "not_provided"}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.primary",
              display: "inline-block",
              marginLeft: "8px",
            }}
          >
            {showFullText
              ? t("pages.onboarding_overview.checkbox")
              : `${t("pages.onboarding_overview.checkbox").slice(0, 300)}... `}
            {t("pages.onboarding_overview.checkbox").length > 300 && (
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowFullText(!showFullText)}
              >
                {!showFullText && t("global.read_more")}
              </Link>
            )}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 6 }}
      >
        <Button
          id="back-button"
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleBack}
        >
          {t("global.back")}
        </Button>
        <Button
          id="register-button"
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={!allRequiredCompleted || !isChecked}
          onClick={async () => {
            if (company?.id) {
              connectSystemsDateMutation.mutateAsync({ id: company.id });
              updatekycKybStatus.mutateAsync({
                companyIri: company["@id"]!,
                status: "provided",
              });
              setCompany({ ...company, kycKybStatus: "provided" });
              navigate(PATH_ONBOARDING.underReview);
            }
          }}
        >
          {t("global.submit")}
        </Button>
      </Stack>
    </Container>
  );
};

type SummaryDetailBoxType = {
  icon_type: string;
  label: CONNECTOR_STEPS;
  stepIndex: number;
  isSkipped: boolean;
};
const SummaryDetailBox = ({
  icon_type,
  label,
  stepIndex,
  isSkipped,
}: SummaryDetailBoxType) => {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: (theme) => theme.palette.grey[300],
        borderRadius: "12px",
        p: 2,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
      >
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
          {icon_type === "info" ? (
            <InfoOutlinedIcon color="info" />
          ) : (
            <CheckCircleOutlineIcon color="success" />
          )}
          <Box>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "text.primary" }}
              >
                {t(stepsDetails[label].summary_title)}
              </Typography>
              {stepsDetails[label].required ? (
                <Box
                  sx={{
                    backgroundColor: "rgba(2, 136, 209, 0.12)",
                    padding: "0px 10px",
                    borderRadius: "6px",
                  }}
                >
                  <Typography variant="caption" sx={{ color: "#0288D1" }}>
                    {t("pages.onboarding_overview.required")}
                  </Typography>
                </Box>
              ) : null}
            </Stack>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {t(stepsDetails[label].summary_desc)}
            </Typography>
          </Box>
        </Stack>
        {isSkipped ? (
          <Button
            variant="outlined"
            onClick={() => {
              if (stepsDetails[label].step !== undefined) {
                const localStorageKey = `lastStep${stepsDetails[label].step}Page`;
                localStorage.setItem(
                  localStorageKey,
                  stepsDetails[label].subStep.toString()
                );
              }
              navigate(stepsDetails[label].path);
            }}
          >
            {stepIndex === 5 || stepIndex === 6
              ? t("global.add")
              : t("global.connect")}
          </Button>
        ) : (
          <Typography
            component="span"
            variant="body2"
            sx={{
              color: "text.primary",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              if (stepsDetails[label].step !== undefined) {
                const localStorageKey = `lastStep${stepsDetails[label].step}Page`;
                localStorage.setItem(
                  localStorageKey,
                  stepsDetails[label].subStep.toString()
                );
              }
              navigate(stepsDetails[label].path);
            }}
          >
            {t("pages.onboarding_overview.edit")}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default ApplicationOverview;
