import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import { useInviteTeamMemberPost } from "../../queries/teamMember";
import { teamMemberRoleOptions } from "../../types/TeamMember";
import { useSearchParams } from "react-router-dom";
import ViolationError, {
  getPasswordErrorMsg,
} from "../../utils/violationError";
import { isLngEN } from "../../utils/i18n";

const SHOW_SUCCESS_MSG_MS = 3000;

type InviteNewMemberModalType = {
  opened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
};

const InviteNewMemberModal = ({
  opened,
  setOpened,
}: InviteNewMemberModalType) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email] = useState(searchParams.get("email") || "");
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [newMemberRole, setNewMemberRole] = useState(teamMemberRoleOptions[0]);
  const handleClose = () => {
    setOpened(false);
  };

  const { mutate: inviteTeamMember } = useInviteTeamMemberPost();

  useEffect(() => {
    if (showSuccessMsg) {
      setTimeout(() => {
        setOpened(false);
        setShowSuccessMsg(false);
        window.location.reload();
      }, SHOW_SUCCESS_MSG_MS);
    }
  }, [setOpened, showSuccessMsg]);

  useEffect(() => {
    if (email) {
      setOpened(true);
      searchParams.delete("email");
      setSearchParams(searchParams);
    }
  }, [email, searchParams, setOpened, setSearchParams]);

  return (
    <Dialog
      open={opened}
      maxWidth="sm"
      PaperProps={{ elevation: 1 }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
    >
      {showSuccessMsg && (
        <Alert severity="success" variant="outlined" sx={{ mx: 4, mt: 3 }}>
          {t("pages.team.invite_member_modal.success")}
        </Alert>
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 5, px: 5, pb: 1.5 }}
      >
        <Typography variant="h3" id="form-dialog-title">
          {t("pages.team.invite_member_modal.heading")}
        </Typography>

        <IconButton disableRipple={true} onClick={handleClose}>
          <Close sx={{ width: "20px", height: "20px" }} />
        </IconButton>
      </Stack>
      <DialogContent sx={{ mb: 5 }}>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email,
            role: newMemberRole.id,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .trim()
              .required(t("error.user.first_name.required"))
              .min(2, ({ min }) => {
                return t("error.user.first_name.min", { limit: min });
              })
              .max(64, ({ max }) => {
                return t("error.user.first_name.max", { limit: max });
              }),
            lastName: Yup.string()
              .trim()
              .required(t("error.user.last_name.required"))
              .min(2, ({ min }) => {
                return t("error.user.last_name.min", { limit: min });
              })
              .max(64, ({ max }) => {
                return t("error.user.last_name.max", { limit: max });
              }),
            email: Yup.string()
              .trim()
              .email(t("error.user.email.invalid"))
              .required(t("error.user.email.required"))
              .max(254, ({ max }) => {
                return t("error.user.email.max", { limit: max });
              }),
            role: Yup.string().trim().required(t("error.user.role.required")),
          })}
          onSubmit={(values, { setSubmitting, setStatus, setErrors }) => {
            setStatus(null);
            inviteTeamMember(
              { ...values },
              {
                onError: (error) => {
                  if (error instanceof ViolationError) {
                    setStatus({
                      isValid: false,
                      msg: `${error.defaultErrorMsg}`,
                    });
                    setErrors(error.fields);
                  } else {
                    setStatus({
                      isValid: false,
                      msg: `${error.message}`,
                    });
                  }
                },
                onSuccess: async () => {
                  setShowSuccessMsg(true);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              }
            );
          }}
        >
          {({ isSubmitting, isValid, status, setFieldValue }) => (
            <Form>
              <Field
                autoComplete="off"
                id="field-firstname"
                type="text"
                name="firstName"
                variant="standard"
                component={TextField}
                label={t("fields.first_name")}
                disabled={showSuccessMsg || isSubmitting}
                sx={{ mb: 4 }}
                fullWidth
                required
              />
              <Field
                autoComplete="off"
                id="field-lastname"
                type="text"
                name="lastName"
                variant="standard"
                component={TextField}
                label={t("fields.last_name")}
                disabled={showSuccessMsg || isSubmitting}
                sx={{ mb: 4 }}
                fullWidth
                required
              />
              <Field
                autoComplete="off"
                id="field-email"
                type="text"
                name="email"
                variant="standard"
                component={TextField}
                label={t("fields.email_address")}
                disabled={showSuccessMsg || isSubmitting}
                sx={{ mb: 4 }}
                fullWidth
                required
              />
              <Autocomplete
                id="field-role"
                autoComplete={false}
                disablePortal
                options={teamMemberRoleOptions}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                value={newMemberRole}
                fullWidth
                disableClearable
                onChange={(e, value) => {
                  setNewMemberRole(value);
                  setFieldValue("role", value.id);
                }}
                disabled={showSuccessMsg || isSubmitting}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    name="role"
                    variant="standard"
                    label={t("fields.role")}
                    required
                  />
                )}
              />
              {status && (
                <Alert
                  severity={status.isValid ? "success" : "error"}
                  variant="outlined"
                  sx={{ my: 3 }}
                >
                  {status.msg}
                </Alert>
              )}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                spacing={4}
                sx={{ mt: 4, width: "100%" }}
              >
                <Button
                  id="cancel-button"
                  variant="outlined"
                  size="large"
                  onClick={handleClose}
                  disabled={showSuccessMsg}
                  fullWidth
                >
                  {t("global.cancel")}
                </Button>

                <Button
                  id="save-button"
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={showSuccessMsg || isSubmitting || !isValid}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size="1.5rem" sx={{ color: "#fff" }} />
                    ) : (
                      <></>
                    )
                  }
                  fullWidth
                >
                  {t("global.send_invite")}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default InviteNewMemberModal;
