import { Box, Stack, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { NAVBAR } from "../../../config/config";
import Header from "./Header";
import Navbar from "./Navbar";
import { isLngAR } from "../../../utils/i18n";
import Logo from "../../Logo";
import LogoAR from "../../Logo/LogoAr";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import CssBaseline from "@mui/material/CssBaseline";
import { useIsMobile } from "../../../hooks/useIsMobile";
import MobileHeader from "./MobileHeader";
export default function Dashboard() {
  const { company } = useAuth();
  const { t } = useTranslation();
  const isMobie = useIsMobile();

  return (
    <>
      <Box>
        {isMobie ? (
          <MobileHeader />
        ) : (
          <>
            <Header />
            <Navbar />
          </>
        )}
        <Box display="flex" flexDirection="column" minHeight="95vh">
          <CssBaseline />
          <Box
            component="main"
            sx={{
              width: { sm: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)` },
              marginLeft: { sm: `${NAVBAR.DASHBOARD_WIDTH + 1}px` },
            }}
          >
            <Outlet />
          </Box>
          {company?.countryCode == "AE" && (
            <Box
              component="footer"
              sx={{
                pb: 5,
                width: { sm: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)` },
                marginLeft: { sm: `${NAVBAR.DASHBOARD_WIDTH + 1}px` },
                mt: "auto",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ pr: { xs: 2, sm: 5 }, pl: { xs: 2, sm: 5 } }}
              >
                <Box sx={{ display: { xs: "none", sm: "block" } }}>
                  {isLngAR() ? (
                    <LogoAR
                      showIcon={false}
                      sxText={{ "& > svg": { fill: "#062F41" } }}
                    />
                  ) : (
                    <Logo
                      showIcon={false}
                      sxText={{ "& > svg": { fill: "#062F41" } }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    color: "#80949E",
                    fontSize: "14px",
                    ml: { sm: 3 },
                    pt: 0.5,
                  }}
                >
                  {t("global.disclosure")}
                </Typography>
              </Stack>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
