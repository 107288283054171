import React, { useMemo, useState } from "react";
import { EradPayModeEnum } from "../types/Payment";

interface ModeContextValue {
  mode: EradPayModeEnum;
  setMode: React.Dispatch<React.SetStateAction<EradPayModeEnum>>;
}

const ModeContext = React.createContext({} as ModeContextValue);
ModeContext.displayName = "ModeContext";

const ModeProvider: React.FC = (props) => {
  const [mode, setMode] = useState(
    process.env.REACT_APP_SITE_ENV === "prod"
      ? EradPayModeEnum.LIVE
      : EradPayModeEnum.SANDBOX
  );

  const value = useMemo(
    () => ({
      mode,
      setMode,
    }),
    [mode, setMode]
  );

  return <ModeContext.Provider value={value} {...props} />;
};

export { ModeContext, ModeProvider };
