import ProgressBar from "../ProgressBar";

const LoadingScreen = () => {
  return (
    <>
      <ProgressBar />
    </>
  );
};

export default LoadingScreen;
