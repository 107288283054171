import i18n from "../utils/i18n";
import { TableRowItem } from "./Table";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export enum TeamMemberRoleIdEnum {
  ROLE_ADMINISTRATOR = 10,
  ROLE_COLLABORATOR = 20,
}

export enum TeamMemberRoleEnum {
  ROLE_COLLABORATOR = "Collaborator",
  ROLE_ADMINISTRATOR = "Administrator",
}

export const teamMemberRoleOptions = [
  {
    id: TeamMemberRoleIdEnum.ROLE_ADMINISTRATOR,
    value: TeamMemberRoleEnum.ROLE_ADMINISTRATOR,
    label: i18n.t("Administrator"),
  },
  {
    id: TeamMemberRoleIdEnum.ROLE_COLLABORATOR,
    value: TeamMemberRoleEnum.ROLE_COLLABORATOR,
    label: i18n.t("Collaborator"),
  },
];

export enum TeamMemberStatusEnum {
  PENDING = "Pending",
  ACTIVE = "Active",
  BLOCKED = "Blocked",
  UNKNOWN = "Unknown",
}

export class TeamMember {
  public "@id"?: string;

  constructor(
    _id?: string,
    public id?: string,
    public fullname?: string,
    public email?: string,
    public status?: string,
    public role?: string
  ) {
    this["@id"] = _id;
  }

  static fromRawData(rawData: any = {}): TeamMember {
    const teamMember = new TeamMember(rawData?.id);
    teamMember.id = teamMember["@id"];
    teamMember.fullname = rawData?.fullName;
    teamMember.email = rawData?.email;
    teamMember.status = !rawData?.status
      ? TeamMemberStatusEnum.UNKNOWN
      : TeamMemberStatusEnum[
          String(
            rawData.status
          ).toUpperCase() as keyof typeof TeamMemberStatusEnum
        ];
    teamMember.role = !rawData?.role
      ? TeamMemberRoleEnum.ROLE_COLLABORATOR
      : teamMemberRoleOptions.find((item) => item.id === rawData.role)?.value;
    return teamMember;
  }

  transformToTableView(excludedLabels: string[] = []): TableRowItem[] {
    const labelsMapping: { [key: string]: string } = {
      fullname: i18n.t("fields.name"),
      email: i18n.t("fields.email"),
      status: i18n.t("fields.status"),
      role: i18n.t("fields.role"),
    };

    const sxMapping: { [key: keyof typeof labelsMapping]: SxProps<Theme> } = {
      status: {
        textAlign: "left",
        alignItems: "left",
        pl: 2,
      },
      role: {
        textAlign: "left",
        alignItems: "left",
        maxWidth: "230px",
      },
    };

    return Object.keys(labelsMapping)
      .filter((label) => !excludedLabels.includes(label))
      .map((modelField) => ({
        key: modelField,
        value: (this[modelField as keyof TeamMember] as string) || "-",
        title: labelsMapping[modelField],
        sx: sxMapping[modelField],
      }));
  }
}
