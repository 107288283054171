import { Stack } from "@mui/material";
import i18n, { isLngAR } from "../../utils/i18n";

type formatPriceProps = {
  price: string | number;
  currency: string;
  maximumFractionDigits?: number;
};

const Price = ({
  price,
  currency,
  maximumFractionDigits = 2,
}: formatPriceProps) => {
  const formatPrice = new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
    maximumFractionDigits,
  });
  const [symbol, ...others] = formatPrice.formatToParts(Number(price));
  const getARCurrencyValue = () => {
    let val = others.filter((dt) => dt.type !== "literal");
    return val.map(({ value }) => value).join("");
  };
  return isLngAR() ? (
    <Stack direction="row-reverse">
      <Stack>&nbsp;{symbol.value}</Stack>
      <Stack>{getARCurrencyValue()}</Stack>
    </Stack>
  ) : (
    <Stack direction="row">
      <Stack>{symbol.value}</Stack>
      <Stack>{others.map(({ value }) => value).join("")}</Stack>
    </Stack>
  );
};

export default Price;
