import React, { useMemo } from "react";
import { useSettingsQuery } from "../queries/settings";
import { Settings } from "../types/Settings";

const SettingsContext = React.createContext({} as Settings);
SettingsContext.displayName = "SettingsContext";

const SettingsProvider: React.FC = (props) => {
  const { isSuccess, data } = useSettingsQuery();
  const phoneCountries = useMemo(
    () =>
      (data?.countries || []).map((country: string) => country.toLowerCase()),
    [data]
  );

  if (isSuccess && data) {
    return (
      <SettingsContext.Provider
        value={{ ...data, phoneCountries }}
        {...props}
      />
    );
  }

  return null;
};

export { SettingsContext, SettingsProvider };
